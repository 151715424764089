import React, { FC } from 'react';

import TemplatePageDefault from 'components/templates/TemplatePageDefault';

import { AllDataTable } from '../../components/organisms/AllDataTable';
import { ConditionalModals } from '../../components/organisms/ConditionalModals';

export const PageAdminReportsAllAudits: FC = () => (
  <TemplatePageDefault hidePageHeader>
    <ConditionalModals />
    <AllDataTable variant="audits" />
  </TemplatePageDefault>
);
