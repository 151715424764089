import { styled } from '@mui/material';
import React from 'react';

import {
  TableCellColorVariant,
  TableCellIconName,
  TableCellWithIcon,
} from 'components/atoms/TableCellWithIcon';
import { CertificateLock, Nullable } from 'types';
import { formatDate } from 'utils/dates';
import { isReinstate } from 'utils/isReinstate';
import { shortenText } from 'utils/shortenText';

import {
  AdminTableCell,
  isAdminAudit,
  isAdminCertificate,
} from '../../../types';
import { DescriptionTooltip } from '../../atoms/DescriptionTooltip';
import { StyledPlainText } from '../../atoms/StyledPlainText';

const StyledLock = styled('div')`
  display: flex;
  margin-bottom: 10px;
`;
const StyledLockIcon = styled(TableCellWithIcon)`
  align-items: flex-start;

  svg {
    font-size: 2rem;
    margin-right: 6px;
  }
`;
const StyledLockTitle = styled('span')`
  font-weight: 600;
`;

export const CertificateLocksCell: AdminTableCell = ({ t, ...restProps }) => {
  let certificate;
  if (isAdminAudit(restProps)) {
    // eslint-disable-next-line react/destructuring-assignment
    certificate = restProps.certificate;
  }

  if (isAdminCertificate(restProps)) {
    certificate = restProps;
  }

  if (!certificate) {
    return null;
  }

  const { activeLock, latestLock } = certificate;

  const renderLock = (
    lock?: Nullable<CertificateLock> | false,
    shorten = false
  ) => {
    if (!lock) {
      return null;
    }

    const { unlockDate, lockDate, type, descriptionEnglish } = lock;

    const isReinstated = isReinstate(lock);
    const variant: TableCellColorVariant = isReinstated ? 'light' : 'error';
    const iconName: TableCellIconName = isReinstated
      ? 'lockOpenOutlined'
      : 'lock';

    return (
      <StyledLock>
        <StyledLockIcon
          iconName={iconName}
          variant={variant}
          showLockIcon
          label=""
        />
        <div>
          {unlockDate && (
            <StyledPlainText>
              <StyledLockTitle>{t('Unlock')}:</StyledLockTitle>{' '}
              {formatDate(unlockDate)}
            </StyledPlainText>
          )}
          <StyledPlainText>
            <StyledLockTitle>{t('Lock')}:</StyledLockTitle>{' '}
            {formatDate(lockDate)}
          </StyledPlainText>
          <StyledPlainText>
            <StyledLockTitle>
              {t('Type', { ns: 'components' })}:
            </StyledLockTitle>{' '}
            {type.name}
          </StyledPlainText>
          <StyledPlainText>
            {shorten ? shortenText(descriptionEnglish) : descriptionEnglish}
          </StyledPlainText>
        </div>
      </StyledLock>
    );
  };

  const getContent = (shorten = false) => (
    <div>{renderLock(activeLock || latestLock, shorten)}</div>
  );

  return (
    <DescriptionTooltip title={t('Locks')} tooltipContent={getContent()}>
      {getContent(true)}
    </DescriptionTooltip>
  );
};
