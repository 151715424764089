/* eslint-disable react/destructuring-assignment */
import { styled } from '@mui/material';
import React from 'react';

import { TEXT } from 'config/appColors';
import { Result, WithUndefined } from 'types';

import {
  AdminTableCell,
  isAdminAudit,
  isAdminCertificate,
} from '../../../types';
import { StyledPlainText as StyledPlainTextBase } from '../../atoms/StyledPlainText';

const StyledPlainText = styled(StyledPlainTextBase)<{ $color?: string }>`
  color: ${({ $color }) => $color};
  span {
    color: ${TEXT.PRIMARY};
  }
`;

const StyledList = styled('ul')`
  padding-left: 25px;
  margin: 0;
`;

export const ResultCell: AdminTableCell = (props) => {
  let result: WithUndefined<Result>;

  if (isAdminCertificate(props)) {
    result = props.auditResult;
  }

  if (isAdminAudit(props)) {
    result = props.result;
  }

  if (!result) {
    return null;
  }

  const { overall, levels } = result;

  return (
    <>
      <StyledPlainText $color={overall.color}>
        {overall.text}
        {overall.percentage && <span> ({overall.percentage})</span>}
      </StyledPlainText>
      {levels && levels.length > 0 && (
        <StyledList>
          {levels.map(({ id, text, percentage }) => (
            <li key={id}>
              <StyledPlainTextBase>
                {text}
                {percentage && <> ({percentage})</>}
              </StyledPlainTextBase>
            </li>
          ))}
        </StyledList>
      )}
    </>
  );
};
