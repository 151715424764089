import React, { FC, PropsWithChildren } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import routes, { routesWithLangRegex } from 'config/routes';
import { UserRoleEnum } from 'types';
import { getReactEnvVar } from 'utils/getReactEnvVar';

import AuthRoute from './AuthRoute';
import {
  PageAdminReportsAllAudits,
  PageAdminReportsAllCertificates,
  PageAdminReportsGeneral,
} from './domains/adminReports';
import { PageAuditAppointmentDetails } from './domains/auditAppointment';
import { PageDiary } from './domains/auditAppointment/pages/PageDiary';
import { PageAuditDetails } from './domains/auditDetails';
import { PageAuditDraftForm } from './domains/auditDraft';
import { PageAuditDraftUpdateDocuments } from './domains/auditDraft/pages/PageAuditDraftUpdateDocuments';
import { PageAuditDraftUpdateDocumentsInit } from './domains/auditDraft/pages/PageAuditDraftUpdateDocumentsInit';
import { PageAuditFileUploadRegularUpload } from './domains/auditFileUpload';
import { PageAuditFileUploadAmendmentUpload } from './domains/auditFileUpload/pages/PageAuditFileUploadAmendmentUpload';
import { PageCertificateDetails } from './domains/certificateDetails';
import { PageCertificateDraftForm } from './domains/certificateDraft';
import { PageCertificateDraftCreate } from './domains/certificateDraft/pages/PageCertificateDraftCreate';
import { PageCertificateDraftUpdate } from './domains/certificateDraft/pages/PageCertificateDraftUpdate';
import { PageSupplierOverview } from './domains/supplier';
import { PageSupplierDetails } from './domains/supplier/pages/PageSupplierDetails';
import { PageLogin } from './domains/user';
import { HomePage } from './domains/user/components/pages/HomePage';
import { PageError } from './domains/user/components/pages/PageError';
import { PageWidgetBase } from './domains/widgets';
import { useForbiddenRedirect } from './hooks/useForbiddenRedirect';
import { isDev, isYiiOnly } from './utils/environments';
import isIframe from './utils/isIframe';
import { LangHandler } from './utils/langHandler';

/**
 * TODO: Once iframe gets removed remove logic responsible for hiding platform routes
 */

const PlatformOnly: FC<PropsWithChildren> = ({ children }) =>
  isYiiOnly ? (
    <Redirect to={routes.IFRAME_BLANK_PAGE} />
  ) : (
    <div>{children}</div>
  );

const AppRoutes: FC = () => {
  useForbiddenRedirect();
  return (
    <Switch>
      <AuthRoute
        path={routesWithLangRegex.HOME}
        exact
        component={() => (
          <PlatformOnly>
            <HomePage />
          </PlatformOnly>
        )}
      />
      {/**
       * TODO: Once iframe gets removed remove logic responsible for hiding platform routes
       */}
      {isDev && (
        <Route
          path={routesWithLangRegex.LOGIN}
          component={() => (
            <PlatformOnly>
              <PageLogin />
            </PlatformOnly>
          )}
        />
      )}
      <AuthRoute
        path={routesWithLangRegex.SUPPLIER_OVERVIEW}
        exact
        component={() => (
          <PlatformOnly>
            <PageSupplierOverview />
          </PlatformOnly>
        )}
      />
      <AuthRoute
        path={routesWithLangRegex.SUPPLIER_DETAILS}
        exact
        component={() => (
          <PlatformOnly>
            <PageSupplierDetails />
          </PlatformOnly>
        )}
      />
      <AuthRoute
        path={routesWithLangRegex.AUDIT_FILE_UPLOAD_REGULAR_UPLOAD}
        exact
        component={() => (
          <PlatformOnly>
            <PageAuditFileUploadRegularUpload />
          </PlatformOnly>
        )}
      />
      <AuthRoute
        path={routesWithLangRegex.AUDIT_FILE_UPLOAD_AMENDMENT_UPLOAD}
        exact
        component={() => (
          <PlatformOnly>
            <PageAuditFileUploadAmendmentUpload />
          </PlatformOnly>
        )}
      />
      <AuthRoute
        path={routesWithLangRegex.AUDIT_APPOINTMENT_DETAILS}
        exact
        component={() => (
          <PlatformOnly>
            <PageAuditAppointmentDetails />
          </PlatformOnly>
        )}
      />
      <AuthRoute path={routesWithLangRegex.DIARY} exact component={PageDiary} />
      <AuthRoute
        path={[
          routesWithLangRegex.AUDIT_DRAFT_FORM,
          routesWithLangRegex.AUDIT_AMENDMENT_FORM,
        ]}
        exact
        component={() => (
          <PlatformOnly>
            <PageAuditDraftForm />
          </PlatformOnly>
        )}
      />
      <AuthRoute
        path={routesWithLangRegex.AUDIT_DRAFT_UPDATE_DOCUMENTS_INIT}
        exact
        component={() => (
          <PlatformOnly>
            <PageAuditDraftUpdateDocumentsInit />
          </PlatformOnly>
        )}
      />
      <AuthRoute
        path={routesWithLangRegex.AUDIT_DRAFT_UPDATE_DOCUMENTS}
        exact
        component={() => (
          <PlatformOnly>
            <PageAuditDraftUpdateDocuments />
          </PlatformOnly>
        )}
      />
      <AuthRoute
        path={routesWithLangRegex.AUDIT_DETAILS}
        exact
        component={() => (
          <PlatformOnly>
            <PageAuditDetails />
          </PlatformOnly>
        )}
      />
      <AuthRoute
        path={routesWithLangRegex.CERTIFICATE_DETAILS}
        exact
        component={() => (
          <PlatformOnly>
            <PageCertificateDetails />
          </PlatformOnly>
        )}
      />
      <AuthRoute
        path={routesWithLangRegex.CERTIFICATE_DRAFT_CREATE}
        exact
        component={() => (
          <PlatformOnly>
            <PageCertificateDraftCreate />
          </PlatformOnly>
        )}
      />
      <AuthRoute
        path={routesWithLangRegex.CERTIFICATE_DRAFT_UPDATE}
        exact
        component={() => (
          <PlatformOnly>
            <PageCertificateDraftUpdate />
          </PlatformOnly>
        )}
      />
      <AuthRoute
        path={routesWithLangRegex.CERTIFICATE_DRAFT_FORM}
        exact
        component={() => (
          <PlatformOnly>
            <PageCertificateDraftForm />
          </PlatformOnly>
        )}
      />
      <AuthRoute
        roles={[UserRoleEnum.ADMIN]}
        path={routesWithLangRegex.ADMIN_REPORTS_ALL_AUDITS}
        exact
        component={PageAdminReportsAllAudits}
      />
      {getReactEnvVar('IFRAME_TARGET_ORIGIN') !==
        'https://ifs-cms.ifs-certification.com' && (
        <AuthRoute
          roles={[UserRoleEnum.ADMIN]}
          path={routesWithLangRegex.ADMIN_REPORTS_ALL_CERTIFICATES}
          exact
          component={PageAdminReportsAllCertificates}
        />
      )}
      <AuthRoute
        path={routesWithLangRegex.ADMIN_REPORTS_GENERAL}
        exact
        component={PageAdminReportsGeneral}
      />
      <Route
        path={routes.PERMISSION_DENIED}
        exact
        component={() => (
          <PageError errorType={403} hideBackButton hideDefaultTemplate />
        )}
      />
      {isIframe && (
        <AuthRoute
          path={routesWithLangRegex.IFRAME}
          component={PageWidgetBase}
        />
      )}
      <Route exact path={routes.IFRAME_BLANK_PAGE} component={() => <div />} />
      <AuthRoute
        path={routes.NOT_FOUND}
        component={() => <PageError errorType={404} />}
      />
      <Route
        render={() => {
          const languageRedirect = LangHandler.urlHasLang()
            ? routes.NOT_FOUND
            : LangHandler.getURLWithLang();
          return <Redirect to={languageRedirect} />;
        }}
      />
    </Switch>
  );
};

export default AppRoutes;
