import { LangHandler } from 'utils/langHandler';
import { UUID_V4 } from 'utils/regex/uuid';

import { AVAILABLE_LANGS } from './languages';

export const AUDIT_AMENDMENT_FORM_GENERAL_URL = 'audit-amendment-form-general';

const routes = {
  HOME: '/',
  LOGIN: '/login',
  AUDIT_PLAN_DETAILS: `/audit-plan/:auditPlanId(${UUID_V4})`,
  AUDIT_PLAN_CREATE: '/audit-plan/create',
  AUDIT_PLAN_DELETE: `/audit-plan/:auditPlanId(${UUID_V4})/delete`,
  AUDIT_PLAN_DUE_DATE: `/audit-plan/:auditPlanId(${UUID_V4})/due-date`,
  IFRAME: '/iframe',
  IFRAME_BLANK_PAGE: '/not-found',
  SUPPLIER_OVERVIEW: '/suppliers',
  SUPPLIER_DETAILS: `/suppliers/:supplierId(${UUID_V4})`,
  AUDIT_FILE_UPLOAD_REGULAR_UPLOAD: `/suppliers/:supplierId(${UUID_V4})/audit-file-upload-general`,
  AUDIT_FILE_UPLOAD_AMENDMENT_UPLOAD: `/suppliers/:supplierId(${UUID_V4})/audit-amendment-create-form-ifs-file-general/:auditId(${UUID_V4})`,
  AUDIT_APPOINTMENT_DETAILS: `/suppliers/:supplierId(${UUID_V4})/audit-appointments/:auditAppointmentId`, // temporary removed regex from auditAppointmentId, because it seems BE are sending v3 instead of v4
  DIARY: '/diary',
  AUDIT_DRAFT_FORM: `/suppliers/:supplierId(${UUID_V4})/audit-draft-form-general/:auditDraftId(${UUID_V4})`,
  AUDIT_AMENDMENT_FORM: `/suppliers/:supplierId(${UUID_V4})/${AUDIT_AMENDMENT_FORM_GENERAL_URL}/:auditDraftId(${UUID_V4})`,
  AUDIT_DRAFT_UPDATE_DOCUMENTS_INIT: `/suppliers/:supplierId(${UUID_V4})/audit/:auditId(${UUID_V4})/document-update`,
  AUDIT_DRAFT_UPDATE_DOCUMENTS: `/suppliers/:supplierId(${UUID_V4})/audit-draft-document-update/:auditDraftId(${UUID_V4})`,
  AUDIT_DETAILS: `/suppliers/:supplierId(${UUID_V4})/audit-details/:auditUuid(${UUID_V4})`,
  CERTIFICATE_DETAILS: `/suppliers/:supplierId(${UUID_V4})/certificate/:certificateUuid(${UUID_V4})`,
  CERTIFICATE_DRAFT_FORM: `/suppliers/:supplierId(${UUID_V4})/certificate-draft-form-general/:certificateDraftId(${UUID_V4})`,
  CERTIFICATE_DRAFT_CREATE: `/suppliers/:supplierId(${UUID_V4})/certificate-draft-form-general`,
  CERTIFICATE_DRAFT_UPDATE: `/suppliers/:supplierId(${UUID_V4})/certificate-draft-create-update-general/:certificateId(${UUID_V4})`,
  PERMISSION_DENIED: '/403',
  NOT_FOUND: '/404',
  ADMIN_REPORTS_ALL_AUDITS: '/admin/reports/all-audits',
  ADMIN_REPORTS_ALL_CERTIFICATES: '/admin/reports/all-certificates',
  ADMIN_REPORTS_GENERAL: '/admin/reports/general',
};

export const langRegex = `/:lang(${AVAILABLE_LANGS.join('|')})`;

export const routesWithLangRegex = new Proxy(routes, {
  get(target, name) {
    return name in target
      ? langRegex + target[name as keyof typeof target]
      : '/';
  },
});

const routesWithLang = new Proxy(routes, {
  get(target, name) {
    const url = name in target ? target[name as keyof typeof target] : '/';
    return LangHandler.getURLWithLang(url);
  },
});

export default routesWithLang;
