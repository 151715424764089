import { set } from 'lodash';

export const convertToNestedObject = (
  payload: Record<string, unknown>
): Record<string, unknown> => {
  const result: Record<string, unknown> = {};

  Object.entries(payload).forEach(([key, value]) => {
    set(result, key, value);
  });

  return result;
};
