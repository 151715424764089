import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import {
  ExtendedLoaderStatusEnum,
  LoaderStatusEnum,
  LoaderStatusWithEmptyEnum,
} from 'types';
import { PLAIN_DATE_REGEX_WITH_DOTS } from 'utils/regex/date';

import {
  AdminDataListParams,
  AdminDataListResponse,
  isAuditsResponse,
  isCertificatesResponse,
} from './types';
import { allDataTableApiConfig } from '../../config/allDataApiConfig';
import { AdminRecord } from '../../types';
import { convertToNestedObject } from '../../utils/convertToNestedObject';

const convertGetAdminAuditListParams = ({
  filter = {},
  ...rest
}: Omit<AdminDataListParams, 'variant'>) => {
  const adjustedFilter: Record<string, unknown> = {};
  Object.entries(filter).forEach(([key, value]) => {
    if (typeof value === 'string' && PLAIN_DATE_REGEX_WITH_DOTS.test(value)) {
      const [day, month, year] = value.split('.');
      adjustedFilter[key] = `${year}-${month}-${day}`;
    }
    /**
     * Other params transformations go here
     */
  });

  return {
    filter: convertToNestedObject({ ...filter, ...adjustedFilter }),
    ...rest,
  };
};

const allDataListApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAdminDataList: build.query<AdminDataListResponse, AdminDataListParams>({
      query: ({ variant, ...params }) => ({
        url: allDataTableApiConfig[variant].dataUrl,
        params: convertGetAdminAuditListParams(params),
      }),
      providesTags: (result, error, { variant }) => [
        allDataTableApiConfig[variant].dataTag,
      ],
      transformResponse: (response: AdminDataListResponse) => {
        if ('certificates' in response) {
          response.certificates = response.certificates.map((certificate) => ({
            ...certificate,
            recordType: 'certificate',
          }));
        }

        if ('audits' in response) {
          response.audits = response.audits.map((audit) => ({
            ...audit,
            recordType: 'audit',
          }));
        }

        return response;
      },
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetAdminDataListQuery = () => {
  const [getAllData, query] = allDataListApi.useLazyGetAdminDataListQuery();
  let status: ExtendedLoaderStatusEnum = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  let data: AdminRecord[] = [];

  if (query.data && isAuditsResponse(query.data)) {
    data = query.data.audits;
  }

  if (query.data && isCertificatesResponse(query.data)) {
    data = query.data.certificates;
  }

  if (status === LoaderStatusEnum.SUCCESS && data.length === 0) {
    status = LoaderStatusWithEmptyEnum.EMPTY;
  }

  return {
    ...query,
    dataList: data,
    total: query.data?.total || 0,
    error,
    status,
    getAllData,
  };
};
