import { FileCopy } from '@mui/icons-material';
import { styled } from '@mui/material';
import React from 'react';

import { useAllDataModal } from '../../../hooks/useAllDataModal';
import { AdminReportsModalEnum } from '../../../state/modal/types';
import { AdminTableCell, isAdminCertificate } from '../../../types';
import { StyledLinkButton as LinkButton } from '../../atoms/StyledLinkButton';
import { StyledPlainText } from '../../atoms/StyledPlainText';

const StyledLinkButton = styled(LinkButton)`
  padding-left: 0;
  margin-top: 10px;
`;

export const CertificateExtensionCell: AdminTableCell = ({
  t,
  ...restProps
}) => {
  const { openModal } = useAllDataModal(restProps);

  const isCertificate = isAdminCertificate(restProps);
  if (
    !isCertificate ||
    (isCertificate &&
      !('hasExtension' in restProps) &&
      typeof restProps.hasExtension !== 'boolean')
  ) {
    return null;
  }

  const { hasExtension } = restProps;

  return (
    <>
      <StyledPlainText>
        {hasExtension
          ? t('Yes', { ns: 'components' })
          : t('No', { ns: 'components' })}
      </StyledPlainText>
      {!!hasExtension && (
        <StyledLinkButton
          onClick={() => openModal(AdminReportsModalEnum.CERTIFICATE_AUDITS)}
        >
          <FileCopy fontSize="large" />
          {t('show Audits')}
        </StyledLinkButton>
      )}
    </>
  );
};
