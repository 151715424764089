import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { usePagination } from 'components/organisms/Table';
import { TablePagination } from 'components/organisms/Table/TablePagination';

import { AllDataTableBody } from './AllDataTableBody';
import { AllDataTableButtons } from './AllDataTableButtons';
import { AllDataTableHead } from './AllDataTableHead';
import { AllDataTableTemplateProvider } from './AllDataTableTemplateProvider';
import { AllDataTableApiVariant } from '../../../config/allDataApiConfig';
import { useFiltersAndSorting } from '../../../hooks/useFiltersAndSorting';
import { useGetAdminDataListQuery } from '../../../state/allDataList/api';
import {
  increaseSearchCounter,
  resetAllDataListState,
} from '../../../state/allDataList/slice';
import { AdminColumn } from '../../../types';
import { AdminTableTemplate } from '../../templates/AdminTableTemplate';

interface RawAllDataTableProps {
  columns: AdminColumn[];
  variant: AllDataTableApiVariant;
}

const RawAllDataTable: FC<RawAllDataTableProps> = ({ columns, variant }) => {
  const {
    pagination,
    limit,
    offset,
    setTotalRowsAmount,
    resetPaginationState,
  } = usePagination({
    enabledQueryParams: true,
    initialRowsPerPage: 50,
  });
  const dispatch = useDispatch();

  const { URLFilters, URLSortState, filtersReadyToFetch } =
    useFiltersAndSorting({
      resetPaginationState,
      columns,
      variant,
      initialCall: true,
    });

  const { dataList, total, status, getAllData } = useGetAdminDataListQuery();

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  useEffect(
    () => () => {
      // reset state on unmount
      dispatch(resetAllDataListState());
    },
    [dispatch]
  );

  useEffect(() => {
    if (filtersReadyToFetch) {
      getAllData(
        {
          filter: URLFilters,
          limit,
          offset,
          ...(URLSortState
            ? { sort: `${URLSortState.key}:${URLSortState.direction}` }
            : {}),
          variant,
        },
        true
      );
      dispatch(increaseSearchCounter(variant));
    }
  }, [
    URLFilters,
    URLSortState,
    limit,
    offset,
    filtersReadyToFetch,
    dispatch,
    getAllData,
    variant,
  ]);

  return (
    <div>
      <AllDataTableButtons variant={variant} />
      <AdminTableTemplate
        thead={<AllDataTableHead columns={columns} variant={variant} />}
        tbody={<AllDataTableBody data={dataList} columns={columns} />}
        tfoot={<TablePagination {...pagination} outsideOfTable />}
        status={status}
      />
    </div>
  );
};

interface AllDataTableProps {
  variant: AllDataTableApiVariant;
}

export const AllDataTable: FC<AllDataTableProps> = ({ variant }) => (
  <AllDataTableTemplateProvider
    variant={variant}
    table={(columns) => <RawAllDataTable variant={variant} columns={columns} />}
  />
);
