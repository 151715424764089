import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  closeAndResetAdminReportsModal,
  selectAdminRecord,
  selectAdminReportsModalName,
} from '../../../state/modal/slice';
import { AdminReportsModalEnum } from '../../../state/modal/types';
import { AdminRecord } from '../../../types';
import { AuditLogsModal } from '../AuditLogsModal';
import { CertificateAuditsModal } from '../CertificateAuditsModal';
import { LockHistoryModal } from '../LockHistoryModal';
import { ScopesModal } from '../ScopesModal';

const MODALS: Record<AdminReportsModalEnum, FC<AdminRecord>> = {
  [AdminReportsModalEnum.AUDIT_LOGS]: AuditLogsModal,
  [AdminReportsModalEnum.LOCK_HISTORY]: LockHistoryModal,
  [AdminReportsModalEnum.SCOPES]: ScopesModal,
  [AdminReportsModalEnum.CERTIFICATE_AUDITS]: CertificateAuditsModal,
};

export const ConditionalModals: FC = () => {
  const adminRecord = useSelector(selectAdminRecord);
  const openModalName = useSelector(selectAdminReportsModalName);
  const modalIsOpen = !!openModalName;
  const dispatch = useDispatch();

  useEffect(
    () => {
      // clear previously opened modal on mount for case when user is leaving without clicking 'x'
      if (modalIsOpen) {
        dispatch(closeAndResetAdminReportsModal());
      }

      return () => {
        dispatch(closeAndResetAdminReportsModal());
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (openModalName && adminRecord) {
    const Modal = MODALS[openModalName];

    return <Modal {...adminRecord} />;
  }

  return null;
};
