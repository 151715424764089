import { SUPPORTED_CELLS_KEYS } from 'domains/adminReports/config/adminTableColumns';
import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import {
  ExtendedLoaderStatusEnum,
  LoaderStatusEnum,
  LoaderStatusWithEmptyEnum,
} from 'types';

import { AllDataTableTemplate } from './types';
import {
  allDataTableApiConfig,
  AllDataTableApiVariant,
} from '../../config/allDataApiConfig';
import { AdminColumn } from '../../types';

const allDataTableTemplateApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAllDataTableTemplate: build.query<AdminColumn[], AllDataTableApiVariant>(
      {
        query: (variant) => ({
          url: allDataTableApiConfig[variant].templateUrl,
        }),
        providesTags: (result, error, variant) => [
          allDataTableApiConfig[variant].templateTag,
        ],
        keepUnusedDataFor: 60,
        // Response transformation:
        //  - remove unsupported columns - system will not handle them anyway
        //  - simplify response
        transformResponse: (response: AllDataTableTemplate) =>
          response?.variables?.columns
            ? response.variables.columns.filter(({ id }) => {
                const result = SUPPORTED_CELLS_KEYS.includes(id);
                if (!result) {
                  console.warn(
                    `System does not support a following cell type: ${id}`
                  );
                }
                return result;
              })
            : [],
      }
    ),
  }),
});

export const useGetAllDataTableTemplateQuery = (
  variant: AllDataTableApiVariant
) => {
  const query =
    allDataTableTemplateApi.useGetAllDataTableTemplateQuery(variant);

  const error = useFormattedError(query, 'formatToAlerts');
  let status: ExtendedLoaderStatusEnum = useRTKQueryStatus(query);

  if (
    status === LoaderStatusEnum.SUCCESS &&
    (!Array.isArray(query.data) || query.data.length === 0)
  ) {
    console.warn(
      `System encountered empty or invalid All ${variant} Table template`
    );
    status = LoaderStatusWithEmptyEnum.EMPTY;
  }

  return {
    ...query,
    template: query.data || [],
    status,
    error,
  };
};
