/* eslint-disable react/destructuring-assignment */
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TabSectionHeading } from 'domains/auditDetails/components/atoms/TabSectionHeading';
import { LockHistoryTab } from 'domains/certificateDetails/components/organisms/LockHistoryTab';
import { WithUndefined } from 'types';

import { AdminRecord, isAdminAudit, isAdminCertificate } from '../../../types';
import { TemplateModal } from '../../templates/TemplateModal';

const StyledHeading = styled(TabSectionHeading)`
  margin-bottom: 15px;
`;

export const LockHistoryModal: FC<AdminRecord> = (props) => {
  const [t] = useTranslation('adminReports');

  let certificateUuid: WithUndefined<string>;

  if (isAdminAudit(props)) {
    certificateUuid = props.certificate?.uuid;
  }

  if (isAdminCertificate(props)) {
    certificateUuid = props.uuid;
  }

  if (!certificateUuid) return null;

  return (
    <TemplateModal title={t('Lock History')}>
      <StyledHeading text={t('History', { ns: 'supplier' })} />
      <LockHistoryTab certificateUuid={certificateUuid} hideActions />
    </TemplateModal>
  );
};
