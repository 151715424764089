import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledLink } from 'components/atoms/StyledLink';
import { StyledResult } from 'components/atoms/StyledResult';
import {
  Column,
  Table,
  useColumnConfig,
  usePagination,
} from 'components/organisms/Table';
import { Audit } from 'types';
import { renderDateRange } from 'utils/dates';

import { yiiUrls } from '../../../config/yiiUrls';
import { useGetCertificateAuditListQuery } from '../../../state/certificateAudits/api';
import {
  AdminCertificate,
  AdminRecord,
  isAdminCertificate,
} from '../../../types';
import { TemplateModal } from '../../templates/TemplateModal';

const CertificateAuditTable: FC<AdminCertificate> = ({ uuid }) => {
  const [t] = useTranslation('adminReports');
  const { pagination, limit, offset, setTotalRowsAmount } = usePagination();
  const { getColumnConfig } = useColumnConfig('15%');

  const { auditList, status, total } = useGetCertificateAuditListQuery({
    certificateUuid: uuid,
    limit,
    offset,
  });

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  const columns: Column<Audit>[] = [
    {
      customCell: ({ auid, supplier, uuid: auditUuid }) => (
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          to={{ pathname: yiiUrls.AUDIT_DETAILS(supplier.uuid, auditUuid) }}
        >
          {auid}
        </StyledLink>
      ),
      headerName: t('Audit ID (AUID)'),
      ...getColumnConfig(),
    },
    {
      customCell: ({ module }) => module?.name,
      headerName: t('Standards/Programs'),
      ...getColumnConfig(),
    },
    {
      customCell: ({ auditTimeframe }) =>
        auditTimeframe
          ? renderDateRange(auditTimeframe.startDate, auditTimeframe.endDate)
          : '-',
      headerName: t('Dates', { ns: 'supplier' }),
      ...getColumnConfig('20%'),
    },
    {
      customCell: ({ result }) => (
        <StyledResult $color={result?.overall?.color}>
          {result?.overall?.text || ''}
        </StyledResult>
      ),
      headerName: t('Result'),
      ...getColumnConfig(),
    },
    {
      customCell: ({ type }) => type?.name,
      headerName: t('Type', { ns: 'supplier' }),
      ...getColumnConfig('20%'),
    },
    {
      customCell: ({ kind }) => kind?.name,
      headerName: t('Option', { ns: 'supplier' }),
      ...getColumnConfig(),
    },
  ];

  return (
    <Table
      rows={auditList}
      columns={columns}
      status={status}
      pagination={pagination}
    />
  );
};

export const CertificateAuditsModal: FC<AdminRecord> = (props) => {
  const [t] = useTranslation('adminReports');

  if (!isAdminCertificate(props)) {
    return null;
  }

  return (
    <TemplateModal title={t('Audits')}>
      <CertificateAuditTable {...props} />
    </TemplateModal>
  );
};
