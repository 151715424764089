import { DateRange } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { FC } from 'react';

interface CalendarIconProps {
  onClick?: () => void;
}

export const CalendarIcon: FC<CalendarIconProps> = ({ onClick }) => (
  <IconButton size="small" onClick={onClick}>
    <DateRange />
  </IconButton>
);
