import { Option, RawOption } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

export const convertRawOptionsToOptions = (options: RawOption[]): Option[] =>
  options.reduce<Option[]>((acc, { enabled, id, text }) => {
    if (enabled || enabled === undefined) {
      // TODO extend option interface so value can be more than string
      acc.push({ value: id as string, label: getTranslatedValue(text) });
    }
    return acc;
  }, []);
