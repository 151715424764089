import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { LoaderIcon } from 'components/atoms/LoaderIcon';
import { ErrorState } from 'components/molecules/ErrorState';

import { AllDataTableApiVariant } from '../../../config/allDataApiConfig';
import { useGetAllDataTableTemplateQuery } from '../../../state/allDataTemplate/api';
import { AdminColumn } from '../../../types';

const StyledContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
`;

interface AllDataTableTemplateProviderProps {
  table: (columns: AdminColumn[]) => ReactNode;
  variant: AllDataTableApiVariant;
}

export const AllDataTableTemplateProvider: FC<AllDataTableTemplateProviderProps> =
  ({ table, variant }) => {
    const { template, isLoading, error } =
      useGetAllDataTableTemplateQuery(variant);

    if (error) {
      return <ErrorState description={error} />;
    }

    if (isLoading) {
      return (
        <StyledContainer>
          <LoaderIcon />
        </StyledContainer>
      );
    }

    return <>{table(template)}</>;
  };
