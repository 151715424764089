import ClearIcon from '@mui/icons-material/Clear';
import { alpha, IconButton, styled } from '@mui/material';
import React, { FC } from 'react';

import { Select } from 'components/atoms/Select';
import { BLACK, TEXT, WHITE } from 'config/appColors';

import { SingleSelectFilter, UseFiltersAndSorting } from '../../../types';
import { convertRawOptionsToOptions } from '../../../utils/convertRawOptionsToOptions';

const StyledSelect = styled(Select)`
  width: 100%;
  background: ${WHITE};
  position: relative;
  border: 1px solid ${alpha(BLACK, 0.23)};

  && .MuiSelect-select {
    color: ${TEXT.PRIMARY};
    padding: 0 5px;
    height: 30px;
    line-height: 30px;
  }

  .MuiSvgIcon-root {
    margin-right: 0;
  }

  .MuiSelect-select__ellipsis {
    width: 85%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const StyledIconButton = styled(IconButton)`
  right: 15px;
  position: absolute;
`;

type SelectFilterCellProps = SingleSelectFilter & UseFiltersAndSorting;

/**
 * Additional styles are placed in GlobalStyles component
 * in AllDataTableHead.tsx file
 * this way we avoid redundancy in adding styles
 */

export const SelectFilterCell: FC<SelectFilterCellProps> = (props) => {
  const { options, setFilterValue, filterKey, filters } = props;

  const value = filters[filterKey];
  const optionsToDisplay = convertRawOptionsToOptions(options);

  const renderValue = (selectedValue: unknown) => {
    const selectedOption = optionsToDisplay.find(
      (option) => String(option.value) === selectedValue
    );
    if (selectedOption) {
      return (
        <div className="MuiSelect-select__ellipsis">{selectedOption.label}</div>
      );
    }
    return undefined;
  };

  return (
    <StyledSelect
      onChange={(event) => {
        setFilterValue({ filterKey, value: event.target.value as string });
      }}
      renderValue={renderValue}
      renderSuffix={() =>
        value?.toString() ? (
          <StyledIconButton
            onClick={() => {
              setFilterValue({ filterKey, value: null });
            }}
          >
            <ClearIcon />
          </StyledIconButton>
        ) : undefined
      }
      // .toString() in case of booleans - unexpected behaviour if value is false
      value={value?.toString() || ''}
      options={optionsToDisplay}
    />
  );
};
