import { isObject } from 'lodash';

import { CertificateLock } from './domain';
import { StartEndDates } from './utils';

const isObjectType = (obj: unknown): obj is Record<string, unknown> =>
  typeof obj === 'object' && !!obj;

export const isCertificateLock = (
  certificateLock: unknown
): certificateLock is CertificateLock =>
  isObjectType(certificateLock) && 'uuid' in certificateLock;

export const isStartEndDates = (date: unknown): date is StartEndDates =>
  isObjectType(date) && 'start' in date && 'end' in date;

export const isArrayOfErrorsWithMessage = (
  errors: unknown
): errors is { message: string }[] =>
  Array.isArray(errors) &&
  errors.every((err) => isObject(err) && 'message' in err);

export const isArrayOfString = (array?: unknown): array is string[] =>
  !!array &&
  Array.isArray(array) &&
  array.every((item) => typeof item === 'string');
