import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LogsTab as AuditLogs } from 'domains/auditDetails/components/organisms/LogsTab';
import { LogsTab as CertificateLogs } from 'domains/certificateDetails/components/organisms/LogsTab';

import { AdminRecord, isAdminAudit } from '../../../types';
import { TemplateModal } from '../../templates/TemplateModal';

export const AuditLogsModal: FC<AdminRecord> = (props) => {
  const [t] = useTranslation('adminReports');
  const isAudit = isAdminAudit(props);
  const { uuid } = props;

  return (
    <TemplateModal
      title={
        isAudit
          ? t('Audit Logs')
          : t('Certificate Logs', { ns: 'certificateDetails' })
      }
    >
      {isAudit ? (
        <AuditLogs
          auditUuid={uuid}
          headerText={t('Details', { ns: 'auditAppointment' })}
        />
      ) : (
        <CertificateLogs
          certificateUuid={uuid}
          headerText={t('Details', { ns: 'auditAppointment' })}
        />
      )}
    </TemplateModal>
  );
};
