import { AxiosError } from 'axios';

import { UNEXPECTED_ERROR } from 'config/constants';
import { isArrayOfErrorsWithMessage, isArrayOfString } from 'types';

export const formatToAlerts = (errParam: unknown): string[] => {
  const err = errParam as AxiosError;
  const errors = err?.response?.data;

  if (errors) {
    if (isArrayOfString(errors)) {
      return errors;
    }

    if (isArrayOfErrorsWithMessage(errors)) {
      return errors.map(({ message }) => message);
    }
  }

  return [UNEXPECTED_ERROR];
};
