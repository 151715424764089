import { endpoints } from './endpoints';

export const allDataTableApiConfig = {
  audits: {
    templateUrl: endpoints.ALL_AUDITS_TEMPLATE,
    templateTag: 'ADMIN_AUDIT_LIST_TEMPLATE',
    dataUrl: endpoints.ALL_AUDITS,
    dataTag: 'ADMIN_AUDIT_LIST',
  },
  certificates: {
    templateUrl: endpoints.ALL_CERTIFICATES_TEMPLATE,
    templateTag: 'ADMIN_CERTIFICATE_LIST_TEMPLATE',
    dataUrl: endpoints.ALL_CERTIFICATES,
    dataTag: 'ADMIN_CERTIFICATE_LIST',
  },
} as const;

export type AllDataTableApiVariant = keyof typeof allDataTableApiConfig;
