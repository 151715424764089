import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { styled } from '@mui/material';
import React from 'react';

import { linkStyles } from 'components/atoms/StyledLink';
import { TEXT } from 'config/appColors';
import { useDownloadAuditDetailsDocumentMutation } from 'domains/auditDetails/state/documentsTab/api';
import { useSnackbarNotification } from 'hooks/useSnackbarNotification';

import { endpoints } from '../../../config/endpoints';
import { AdminTableCell, isAdminCertificate } from '../../../types';

const StyledDocumentList = styled('ul')`
  margin: 0;
  padding: 0;
  list-style: none;
`;
const StyledDocumentIcon = styled(UploadFileOutlinedIcon)`
  font-size: 1.7rem;
  transform: rotate(180deg);
  vertical-align: middle;
  color: ${TEXT.SECONDARY_DARK};
`;
const StyledDocumentDownloadButton = styled('button')`
  padding: 0 0 3px 6px;
  ${linkStyles};
  cursor: pointer;
  border: 0;
  background: 0;
`;

export const DocumentsCell: AdminTableCell = ({
  documents,
  uuid: auditUuid,
  t,
  ...restProps
}) => {
  const { downloadFile } = useDownloadAuditDetailsDocumentMutation();
  const { openSnackbar } = useSnackbarNotification();
  const handleFileDownload = (documentUuid: string, fileName: string) => {
    downloadFile({
      auditUuid,
      documentUuid,
      fileName,
      ...(isAdminCertificate(restProps) && {
        customUrl: endpoints.CERTIFICATES_DOCUMENT_DETAILS,
      }),
    }).catch(() =>
      openSnackbar(t('Cannot download a file.', { ns: 'components' }), 'error')
    );
  };

  if (!documents) {
    return null;
  }

  return (
    <StyledDocumentList>
      {documents.map(({ uuid, type, language, fileName }) => {
        const langInfo = language ? `(${language.toUpperCase()})` : '';

        return (
          <li key={uuid}>
            <StyledDocumentIcon />
            <StyledDocumentDownloadButton
              type="button"
              onClick={() => handleFileDownload(uuid, fileName)}
            >
              {type.name} {langInfo}
            </StyledDocumentDownloadButton>
          </li>
        );
      })}
    </StyledDocumentList>
  );
};
