import { LANG_PARAM } from 'config/languages';

export const endpoints = {
  ALL_AUDITS: `/${LANG_PARAM}/admin/reports/allAudits`,
  ALL_AUDITS_TEMPLATE: `/${LANG_PARAM}/admin/reports/allAudits/template`,
  ALL_CERTIFICATES: `/${LANG_PARAM}/admin/reports/allCertificates`,
  ALL_CERTIFICATES_TEMPLATE: `/${LANG_PARAM}/admin/reports/allCertificates/template`,
  CERTIFICATES_DOCUMENT_DETAILS: (
    certificateUuid: string,
    documentUuid: string
  ): string =>
    `/${LANG_PARAM}/certificates/${certificateUuid}/documents/${documentUuid}`,
  CERTIFICATE_AUDIT_LIST: (certificateUuid: string): string =>
    `/${LANG_PARAM}/certificates/${certificateUuid}/audits`,
};
