import React from 'react';

import { formatDate } from 'utils/dates';

import { AdminTableCell, isAdminCertificate } from '../../../types';
import { StyledPlainText } from '../../atoms/StyledPlainText';

export const CertificateCreatedAtCell: AdminTableCell = (props) => {
  if (!isAdminCertificate(props)) {
    return null;
  }

  const { createdAt } = props;

  return <StyledPlainText>{formatDate(createdAt)}</StyledPlainText>;
};
