/* eslint-disable @typescript-eslint/naming-convention */
import { styled } from '@mui/material';
import React from 'react';

import { shortenText } from 'utils/shortenText';

import {
  AdminTableCell,
  isAdminAudit,
  isAdminCertificate,
} from '../../../types';
import { DescriptionTooltip } from '../../atoms/DescriptionTooltip';
import { StyledPlainText as PlainText } from '../../atoms/StyledPlainText';

const StyledPlainText = styled(PlainText)`
  line-height: 2rem;
`;

export const ScopesDescriptionCell: AdminTableCell = ({ t, ...restProps }) => {
  let scopeDescriptionEn = '';
  let scopeDescription = '';

  if (isAdminAudit(restProps)) {
    const { scopeDescriptionAudit = '', scopeDescriptionAudit_en = '' } =
      restProps;
    scopeDescriptionEn = scopeDescriptionAudit_en;
    scopeDescription = scopeDescriptionAudit;
  }
  if (isAdminCertificate(restProps)) {
    const {
      scopeDescriptionCertificate = '',
      scopeDescriptionCertificate_en = '',
    } = restProps;
    scopeDescriptionEn = scopeDescriptionCertificate_en;
    scopeDescription = scopeDescriptionCertificate;
  }

  const showTooltip =
    scopeDescriptionEn.length >= 150 || scopeDescription.length >= 150;

  const getContent = (shorten = false) => (
    <>
      {scopeDescriptionEn && (
        <>
          <StyledPlainText>
            {shorten ? shortenText(scopeDescriptionEn) : scopeDescriptionEn}
          </StyledPlainText>
          <br />
        </>
      )}
      <StyledPlainText>
        {shorten ? shortenText(scopeDescription) : scopeDescription}
      </StyledPlainText>
    </>
  );

  return showTooltip ? (
    <DescriptionTooltip
      title={t('Scope Description')}
      tooltipContent={getContent()}
    >
      <div>{getContent(true)}</div>
    </DescriptionTooltip>
  ) : (
    getContent(true)
  );
};
