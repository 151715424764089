import { v4 as uuidV4 } from 'uuid';

import { AuditorParticipant, RoleMapper } from 'types';

type AuditorParticipantWithKey = AuditorParticipant & { key: string };

export const getAuditParticipantsToDisplay = <T extends AuditorParticipant>(
  auditorParticipants: T[],
  roleMapper: RoleMapper[]
): (T & AuditorParticipantWithKey)[] => {
  const auditorParticipantsWithUniqueKeys = auditorParticipants.map(
    (auditorParticipant) => ({
      ...auditorParticipant,
      key: auditorParticipant.uuid || uuidV4(),
    })
  );

  /**
   * Grouping based on role - order of records should follow order in roleMapper
   */

  const auditorParticipantsSorted = roleMapper.reduce<
    (T & AuditorParticipantWithKey)[]
  >((acc, { role }) => {
    const auditorParticipantsBasedOnRole =
      auditorParticipantsWithUniqueKeys.filter(
        (auditor) => auditor.role === role
      );

    return [...acc, ...auditorParticipantsBasedOnRole];
  }, []);

  return auditorParticipantsSorted;
};
