import { styled } from '@mui/material';
import React, { FC } from 'react';

import { StatusIndicatorWithIcon } from 'components/atoms/StatusIndicator';
import { RawOption } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { AdminRecord } from '../../../types';
import { DescriptionTooltip, TooltipBoundaryEnum } from '../DescriptionTooltip';

interface TagProps {
  tag: string;
  tagOptions: RawOption[];
  record: AdminRecord;
}

const StyledTagWrapper = styled('div')`
  margin-bottom: 4px;

  span {
    white-space: nowrap;
  }
`;

const descriptionFieldNames = [
  {
    type: 'outsourced-production',
    values: [
      'outsourcedProcessesProductsDescription_en',
      'outsourcedProcessesProductsDescription',
    ],
  },
  {
    type: 'exclusions',
    values: ['exclusionsDescription_en', 'exclusionsDescription'],
  },
  {
    type: 'fsma',
    values: ['fsmaDescription_en', 'fsmaDescription'],
  },
] as const;

export const Tag: FC<TagProps> = ({ tag, tagOptions, record }) => {
  const iconData = tagOptions.find((option) => option.id === tag);

  if (!iconData) {
    return null;
  }

  const objectWithDescriptions =
    'certificate' in record && record.certificate?.tags
      ? record.certificate
      : record;

  const description = descriptionFieldNames
    .find(({ type }) => type === iconData.id)
    ?.values.map((descName) => {
      if (descName in objectWithDescriptions) {
        // TODO: adjust this component to avoid the following assertion
        // assertion is safe thanks to if check above
        return (objectWithDescriptions as unknown as Record<string, string>)[
          descName
        ];
      }

      return '';
    });

  const descriptionWithoutEmptyValues = (description || []).filter(
    (desc) => desc
  );
  const showTooltip = descriptionWithoutEmptyValues.length > 0;
  const title = getTranslatedValue(iconData.text);

  const tagButton = (
    <StyledTagWrapper>
      <StatusIndicatorWithIcon iconData={iconData} />
    </StyledTagWrapper>
  );

  return showTooltip ? (
    <DescriptionTooltip
      title={title}
      tooltipContent={
        <div>
          {descriptionWithoutEmptyValues.map((desc, descIndex) => (
            <p key={`${desc?.slice(0, 10 + descIndex)}`}>{desc}</p>
          ))}
        </div>
      }
      tooltipPadding={{ top: 21, left: 38 }}
      boundary={TooltipBoundaryEnum.BUTTON}
    >
      {tagButton}
    </DescriptionTooltip>
  ) : (
    <>{tagButton}</>
  );
};
