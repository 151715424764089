export const MISSING_PARAMS_ERROR =
  'An unexpected error occurred. One or more values are missing.';
export const UNEXPECTED_ERROR =
  'An unexpected error occurred. Please try again.';
export const UNEXPECTED_ERROR_EXTENDED =
  'An unexpected error occurred. Please apologize the inconvenience and contact the customer support with the following error code';
export const UNEXPECTED_ERROR_TRY_AGAIN =
  'An unexpected error occurred. Please apologize the inconvenience and try again later.';
export const INCORRECT_DATA_ERROR = 'Incorrect credentials';
export const NO_PERMISSION_ERROR = 'Permission denied';
export const DATA_INCOSISTENCY_ERROR = 'Data inconsistency detected';
export const DATE_FORMAT = 'dd.MM.yyyy';
export const BASE64_OUTPUT_ERROR =
  'Failed to convert file to base 64. Please check your file.';
export const UNEXISTING_AUDIT_ID = 'e70c60c8-a4ab-43ad-a50d-8c4aec4873fb';
export const FORBIDDEN_AUDIT_ID = '53123a18-0a1e-43d7-a191-be1a613ac66a';
export const REASON_OTHER = 'other';
export const AUDIT_MODAL_NEXT_ACTION_TEST_ID = 'modal-next-btn';
export const AUDIT_MODAL_PREV_ACTION_TEST_ID = 'modal-prev-btn';
export const DEACTIVATED_AUDIT_ID = '90be8751-dc92-4167-a8ae-a5c5f167931b';
export const MOCK_ERROR_SUPPLIER_ID = '9e2a4f4b-0a07-431c-9ab5-afba07060207';
export const MOCKED_CANCELLED_DRAFT_ID = '617586b1-d3a8-4c09-8e39-d5b293a9fc8f';
export const MOCKED_SUBMITTED_DRAFT_ID = '332c06d5-f45f-449f-a6ff-17bd630a204e';
export const MOCKED_UNSUBMITTED_DRAFT_ID =
  'c136fe30-cda5-4193-8238-f0d1085c9ef9';
export const UNEXISTING_DRAFT_ID = '368afe5b-0c31-49a2-adf2-e5ff8b6f0e81';
export const FAILURE_DRAFT_ID = 'd085f9b2-c5ec-4b49-818f-fc98d63e68d9';
export const MOCKED_EXISTING_SUPPLIER_ID =
  '4c4c33fb-85b4-407c-ac59-536da4548678';
export const AUDITS_ASSESSMENTS_TAB_SLUG = 'auditsassessments';
export const CERTIFICATES_CONFIRMATIONS_TAB_SLUG = 'certificatesconfirmations';
export const APPOINTMENT_REGISTRATION_OVERVIEW_TAB_SLUG =
  'appointmentregistration-overview';
export const MOCKED_CERTIFICATE_ID_WITH_ERROR =
  '90be8751-dc92-4167-a8ae-a5c5f167931b';
