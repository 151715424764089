import React from 'react';

import {
  AdminTableCell,
  FilterTypeEnum,
  isAdminAudit,
  isAdminCertificate,
} from '../../../types';
import { Tag } from '../../atoms/Tag';

export const TagsCell: AdminTableCell = (props) => {
  let tags: string[] = [];

  if (isAdminAudit(props)) {
    const { certificate, tags: auditTags } = props;
    tags = certificate?.tags || auditTags || [];
  }

  if (isAdminCertificate(props)) {
    const { tags: certificateTags } = props;

    tags = certificateTags || [];
  }

  const tagFilterKeys = ['auditTags', 'tags'];

  const filtersConfig = props?.filtersConfig?.find(
    (i) =>
      i.type === FilterTypeEnum.MULTISELECT &&
      tagFilterKeys.includes(i.filterKey)
  );
  const tagOptions =
    filtersConfig && 'options' in filtersConfig ? filtersConfig.options : [];

  return (
    <>
      {tags.map((tag) => (
        <Tag
          key={tag}
          tag={tag}
          record={props}
          tagOptions={tagOptions}
          {...props}
        />
      ))}
    </>
  );
};
