import { styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { linkStyles } from 'components/atoms/StyledLink';

import { FILTER_DEFAULT_MIN_LENGTH } from '../../../config/constants';
import { selectSearchCounter } from '../../../state/allDataList/slice';
import { TextFieldFilter, UseFiltersAndSorting } from '../../../types';
import { StyledTextField } from '../../atoms/StyledTextField';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledExactButton = styled('button')`
  && {
    ${linkStyles};
    display: flex;
    margin-left: auto;
    padding: 0;
    font-size: 1rem;
    line-height: 1.4rem;
    cursor: pointer;
    border: 0;
    background: 0;
  }
`;

type TextFieldFilterCellProps = TextFieldFilter & UseFiltersAndSorting;

const QUOTE_MARKS = '"';

export const TextFieldFilterCell: FC<TextFieldFilterCellProps> = ({
  filters,
  filterKey,
  minLength = FILTER_DEFAULT_MIN_LENGTH,
  setFilterValue,
  exact = true,
}) => {
  const [t] = useTranslation('adminReports');
  const value = filters[filterKey] as string;
  const [inputValue, setInputValue] = useState(value);
  const searchCounter = useSelector(selectSearchCounter);

  const handleSearchPhraseChange = (val: string, updateInputValue = true) => {
    let searchPhrase = val.trim();
    if (val.startsWith(QUOTE_MARKS)) searchPhrase = searchPhrase.slice(1);
    if (val.endsWith(QUOTE_MARKS))
      searchPhrase = searchPhrase.slice(0, searchPhrase.length - 1);

    if (updateInputValue) setInputValue(val);
    setFilterValue({
      filterKey,
      // reset value to avoid sending old search phrase (e.g. when user changes from "test" to "te")
      value: searchPhrase.length >= minLength ? val : '',
    });
  };

  const addQuoteMarksToText = () => {
    setInputValue((prevValue) => {
      let val = prevValue;

      if (!val) return val;
      if (!val.startsWith(QUOTE_MARKS)) val = `${QUOTE_MARKS}${val}`;
      if (!val.endsWith(QUOTE_MARKS)) val += QUOTE_MARKS;

      handleSearchPhraseChange(val, false);

      return val;
    });
  };

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCounter]);

  return (
    <StyledContainer>
      <StyledTextField
        value={inputValue}
        onChange={(ev) => handleSearchPhraseChange(ev.target.value)}
      />
      {exact && (
        <StyledExactButton onClick={addQuoteMarksToText}>
          {t('exact')}
        </StyledExactButton>
      )}
    </StyledContainer>
  );
};
