import { TableBody as MuiTableBody, TableRow } from '@mui/material';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { generateId } from 'utils/generateId';

import { AllDataTableCell } from './AllDataTableCell';
import { AdminColumn, AdminRecord } from '../../../types';

interface Props {
  data: AdminRecord[];
  columns: AdminColumn[];
}

const arePropsEqual = ({ data: oldData }: Props, { data }: Props): boolean =>
  JSON.stringify(data) === JSON.stringify(oldData);

export const AllDataTableBody: FC<Props> = memo((props) => {
  const { data, columns } = props;
  const [t] = useTranslation('adminReports');

  return (
    <MuiTableBody>
      {data.map((row) => (
        <TableRow key={row.uuid}>
          {columns.map((column, index) => (
            <AllDataTableCell
              key={generateId(column.id, index)}
              adminRecord={row}
              columnConfig={column}
              t={t}
            />
          ))}
        </TableRow>
      ))}
    </MuiTableBody>
  );
}, arePropsEqual);
