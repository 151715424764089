import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';

import { Table, usePagination } from 'components/organisms/Table';
import { useLogColumns } from 'hooks/useLogColumns';
import { TranslatedText } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { useGetCertificateLogsQuery } from '../../../state/certificateLogsTab/api';
import { StyledHeading } from '../../atoms/StyledHeading';

const StyledTable = styled(Table)`
  tbody td {
    vertical-align: top;
  }
` as typeof Table;

interface LogsTabProps {
  certificateUuid: string;
  headerText: TranslatedText | string;
}

export const LogsTab: FC<LogsTabProps> = (props) => {
  const { certificateUuid, headerText } = props;
  const { pagination, limit, offset, setTotalRowsAmount } = usePagination({
    initialRowsPerPage: 20,
  });
  const { error, status, logs, total } = useGetCertificateLogsQuery({
    params: {
      limit,
      offset,
    },
    uuid: certificateUuid,
  });

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  const columns = useLogColumns();

  return (
    <>
      <StyledHeading variant="h3">
        {typeof headerText === 'string'
          ? headerText
          : getTranslatedValue(headerText)}
      </StyledHeading>
      <StyledTable
        rows={logs}
        columns={columns}
        status={status}
        error={error}
        pagination={pagination}
        propertyAsAKey="executedAt"
        dense
      />
    </>
  );
};
