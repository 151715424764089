import { SortStateType } from 'components/organisms/Table/types';
import { Nullable } from 'types';

import { AllDataTableApiVariant } from '../../config/allDataApiConfig';
import { AdminAudit, AdminCertificate, FilterType } from '../../types';

// api

export interface AdminDataListParams {
  limit?: number;
  offset?: number;
  sort?: string;
  filter?: Record<string, unknown>;
  variant: AllDataTableApiVariant;
}

export type AdminDataListResponse =
  | {
      audits: AdminAudit[];
      total: number;
    }
  | {
      certificates: AdminCertificate[];
      total: number;
    };

export const isAuditsResponse = (
  data: AdminDataListResponse
): data is { audits: AdminAudit[]; total: number } => 'audits' in data;

export const isCertificatesResponse = (
  data: AdminDataListResponse
): data is { certificates: AdminCertificate[]; total: number } =>
  'certificates' in data;

export interface AllDataFiltersAndSortStatePerCollection {
  filtersReadyToFetch: boolean;
  filters: Record<string, FilterType>;
  sortState: Nullable<SortStateType>;
  searchCounter: number;
}

export type AllDataFiltersAndSortState = Record<
  AllDataTableApiVariant,
  AllDataFiltersAndSortStatePerCollection
>;
