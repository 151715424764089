import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import { Nullable } from 'types';

import { AdminReportsModalEnum, AdminReportsModalState } from './types';
import { parentReducerName } from '../../config/constants';
import { AdminRecord } from '../../types';

const initialState: AdminReportsModalState = {
  openModalName: null,
  record: null,
};

const reducerName = `${parentReducerName}/modal`;

const adminReportsModalSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    openAdminReportsModal: (
      state,
      action: PayloadAction<Nullable<AdminReportsModalEnum>>
    ) => {
      state.openModalName = action.payload;
    },
    setAdminRecord: (state, action: PayloadAction<Nullable<AdminRecord>>) => {
      state.record = action.payload;
    },
    closeAndResetAdminReportsModal: () => initialState,
  },
});

// actions
export const {
  openAdminReportsModal,
  closeAndResetAdminReportsModal,
  setAdminRecord,
} = adminReportsModalSlice.actions;

// selectors
export const selectAdminReportsModalName = (
  state: RootState
): Nullable<AdminReportsModalEnum> =>
  state[parentReducerName].adminReportsModal.openModalName;

export const selectAdminRecord = (state: RootState): Nullable<AdminRecord> =>
  state[parentReducerName].adminReportsModal.record;

// reducer
export default adminReportsModalSlice.reducer;
