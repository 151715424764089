import { styled } from '@mui/material';
import React from 'react';

import { StatusIndicator } from 'components/atoms/StatusIndicator';
import { StyledLink } from 'components/atoms/StyledLink';
import { ERROR } from 'config/appColors';
import { AuditStatusEnum } from 'types';

import { yiiUrls } from '../../../config/yiiUrls';
import { AdminTableCell, isAdminAudit } from '../../../types';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

export const IdCell: AdminTableCell = (props) => {
  const { supplier, uuid, status, auid, ceid } = props;

  const idToDisplay = (auid || ceid) as string;
  const url = isAdminAudit(props)
    ? yiiUrls.AUDIT_DETAILS
    : yiiUrls.CERTIFICATE_DETAILS;

  return (
    <StyledContainer>
      <StyledLink
        target="_blank"
        rel="noopener noreferrer"
        to={{ pathname: url(supplier.uuid, uuid) }}
      >
        {idToDisplay}
      </StyledLink>
      {status.id === AuditStatusEnum.DELETED && (
        <StatusIndicator label={status.name} bgColor={ERROR.LIGHT} />
      )}
    </StyledContainer>
  );
};
