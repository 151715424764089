import React from 'react';

import { formatDate } from 'utils/dates';

import {
  AdminTableCell,
  isAdminAudit,
  SupportedMessageEnum,
} from '../../../types';
import { DateWarningTooltip } from '../../atoms/DateWarningTooltip';

export const UploadedCell: AdminTableCell = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (!isAdminAudit(props) || !props.uploadedAt) {
    return null;
  }

  const { uploadedAt } = props;

  const date = formatDate(uploadedAt);

  return (
    <DateWarningTooltip
      date={date}
      auditProps={props}
      supportedMessages={[SupportedMessageEnum.TOO_LATE_UPLOAD]}
    />
  );
};
