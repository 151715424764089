import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import {
  CertificateAuditListParams,
  CertificateAuditListResponse,
} from './types';
import { endpoints } from '../../config/endpoints';

const initialState: CertificateAuditListResponse = {
  audits: [],
  total: 0,
};

const certificateAuditListApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCertificateAuditList: build.query<
      CertificateAuditListResponse,
      CertificateAuditListParams
    >({
      query: ({ certificateUuid, ...params }) => ({
        url: endpoints.CERTIFICATE_AUDIT_LIST(certificateUuid),
        params,
      }),
      providesTags: ['CERTIFICATE_AUDIT_LIST'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetCertificateAuditListQuery = (
  params: CertificateAuditListParams
) => {
  const query = certificateAuditListApi.useGetCertificateAuditListQuery(params);
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    auditList: query.data?.audits || initialState.audits,
    total: query.data?.total || initialState.total,
    error,
    status,
  };
};
