import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { ErrorBoundary } from 'components/molecules/ErrorBoundary';
import { ErrorState } from 'components/molecules/ErrorState';
import { HEADER_HEIGHT as APP_HEADER_HEIGHT } from 'components/templates/TemplatePageDefault';
import { UNEXPECTED_ERROR } from 'config/constants';

import { ALL_AUDITS_HEADER_HEIGHT } from '../../../config/constants';

const StyledTd = styled('td')<{ $width?: number }>`
  position: sticky;
  left: 0;
  right: 0;
  z-index: 10;
  width: ${({ $width = 400 }) => `${$width}px`};
  margin-top: calc(
    30vh - ${APP_HEADER_HEIGHT}px - ${ALL_AUDITS_HEADER_HEIGHT}px
  );
  display: block;

  &&& {
    border: none;
  }

  * {
    font-size: 1.5rem;
  }

  svg {
    font-size: 2.5rem;
  }
`;

interface ErrorFallbackProps {
  width?: number;
}

const ErrorFallback: FC<ErrorFallbackProps> = ({ width }) => (
  <tbody>
    <tr>
      <StyledTd $width={width}>
        <ErrorState heading={UNEXPECTED_ERROR} dense />
      </StyledTd>
    </tr>
  </tbody>
);

interface AllDataTableErrorBoundaryProps {
  children: ReactNode;
  width?: number;
}

export const AllDataTableErrorBoundary: FC<AllDataTableErrorBoundaryProps> = ({
  children,
  width,
}) => (
  <ErrorBoundary fallback={<ErrorFallback width={width} />}>
    {children}
  </ErrorBoundary>
);
