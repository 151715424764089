import React from 'react';

import { AdminTableCell, isAdminCertificate } from '../../../types';
import { StyledPlainText } from '../../atoms/StyledPlainText';

export const StatusCell: AdminTableCell = (props) => {
  if (!isAdminCertificate(props)) {
    return null;
  }

  const { status } = props;

  return <StyledPlainText>{status.name}</StyledPlainText>;
};
