import { styled } from '@mui/material';
import React from 'react';

import { StyledLink } from 'components/atoms/StyledLink';
import { getAuditParticipantsToDisplay } from 'utils/getAuditParticipantsToDisplay';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { auditorRoles } from '../../../config/auditorRoles';
import { AdminTableCell, isAdminAudit, Scope } from '../../../types';
import { DescriptionTooltip } from '../../atoms/DescriptionTooltip';
import { StyledPlainText } from '../../atoms/StyledPlainText';

const StyledTooltipAuditor = styled('div')`
  margin-bottom: 10px;
`;
const StyledTooltipContentTitle = styled('span')`
  font-weight: 700;
`;
const StyledScopeList = styled('ul')`
  padding: 0;
  margin: 0 0 0 24px;
`;
const StyledAuditor = styled(StyledPlainText)`
  margin: 0 0 5px 0;
`;
const StyledAuditorLink = styled(StyledLink)`
  font-weight: 600;
`;

export const AuditorsCell: AdminTableCell = (props) => {
  if (!isAdminAudit(props)) {
    return null;
  }
  const { auditors = [], t } = props;
  const sortedAuditors = getAuditParticipantsToDisplay(auditors, auditorRoles);

  const getAuditorRole = (role: string) => {
    const mappedRole = auditorRoles.find(
      ({ role: configRole }) => configRole === role
    )?.name;

    return mappedRole ? ` (${getTranslatedValue(mappedRole)})` : '';
  };
  const renderScopes = (title: string, scopes?: Scope[]) =>
    scopes && (
      <li>
        <StyledTooltipContentTitle>{title}: </StyledTooltipContentTitle>
        {scopes.map(({ branchKey }) => branchKey).join(', ')}
      </li>
    );

  const tooltipContent = (
    <div>
      {sortedAuditors.map(
        ({ uuid, firstName, lastName, userId, role, scopes }) => {
          const { productScopes, techScopes } = scopes || {};

          return (
            <StyledTooltipAuditor key={uuid}>
              <StyledTooltipContentTitle>
                {firstName} {lastName}
              </StyledTooltipContentTitle>
              {userId && ` (AID: ${userId})`}
              {getAuditorRole(role)}
              <StyledScopeList>
                {renderScopes(t('Product Scopes'), productScopes)}
                {renderScopes(t('Technology Scopes'), techScopes)}
              </StyledScopeList>
            </StyledTooltipAuditor>
          );
        }
      )}
    </div>
  );

  return (
    <DescriptionTooltip
      title={t('Auditor Details')}
      tooltipContent={tooltipContent}
      tooltipPadding={{ top: 35, left: 35 }}
    >
      <div>
        {sortedAuditors.map(({ uuid, firstName, lastName, role }) => {
          const key = `${role}_${uuid}`;

          return (
            <StyledAuditor key={key}>
              <StyledAuditorLink as="span">
                {firstName} {lastName}
              </StyledAuditorLink>
              {getAuditorRole(role)}
            </StyledAuditor>
          );
        })}
      </div>
    </DescriptionTooltip>
  );
};
