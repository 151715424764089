import { styled } from '@mui/material';
import React, { Fragment } from 'react';

import { ScopeValue } from 'components/atoms/AttributeRow';
import { linkStyles } from 'components/atoms/StyledLink';
import { TEXT } from 'config/appColors';
import { NestedGeneralDetails } from 'types';

import { useAllDataModal } from '../../../hooks/useAllDataModal';
import { AdminReportsModalEnum } from '../../../state/modal/types';
import {
  AdminTableCell,
  isAdminAudit,
  isAdminCertificate,
} from '../../../types';

const StyledTitle = styled('p')`
  font-weight: 700;
  letter-spacing: 0.017rem;
  margin: 0;
  color: ${TEXT.PRIMARY};
`;

const StyledList = styled('ul')`
  padding-left: 23px;
  margin: 0;
  color: ${TEXT.PRIMARY};

  li {
    font-weight: 400;

    p {
      line-height: 2rem;
    }
  }
`;

export const Scopes: AdminTableCell<{ showAllScopesLevels?: boolean }> = ({
  t,
  className,
  showAllScopesLevels,
  ...restProps
}) => {
  let productScopes: NestedGeneralDetails[] = [];
  let techScopes: NestedGeneralDetails[] = [];
  let processingSteps: NestedGeneralDetails[] = [];

  if (isAdminAudit(restProps)) {
    const {
      productScopesAudit = [],
      techScopesAudit = [],
      processingStepsAudit = [],
    } = restProps;

    productScopes = productScopesAudit;
    techScopes = techScopesAudit;
    processingSteps = processingStepsAudit;
  }

  if (isAdminCertificate(restProps)) {
    const {
      productScopesCertificate = [],
      techScopesCertificate = [],
      processingStepsCertificate = [],
    } = restProps;

    productScopes = productScopesCertificate;
    techScopes = techScopesCertificate;
    processingSteps = processingStepsCertificate;
  }

  const combinedScopes = [
    {
      maxNestingLevel: 1,
      title: t('Product Scopes'),
      scopes: productScopes,
    },
    {
      maxNestingLevel: 3,
      title: t('Technology Scopes'),
      scopes: techScopes,
    },
    {
      maxNestingLevel: 1,
      title: t('Processing Steps'),
      scopes: processingSteps,
    },
  ].filter(({ scopes }) => scopes.length);

  if (!combinedScopes.length) {
    return null;
  }

  return (
    <div className={className}>
      {combinedScopes.map(({ title, scopes, maxNestingLevel }) => (
        <Fragment key={title}>
          <StyledTitle className="title">{title}</StyledTitle>
          <StyledList>
            {scopes.map((scope) => (
              <ScopeValue
                key={scope.id}
                scope={scope}
                maxNestingLevel={showAllScopesLevels ? 10 : maxNestingLevel}
              />
            ))}
          </StyledList>
        </Fragment>
      ))}
    </div>
  );
};

const StyledScopes = styled(Scopes)`
  li {
    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

const StyledCell = styled('div')`
  cursor: pointer;

  .title,
  li {
    ${linkStyles};
  }

  .title {
    text-decoration: none;
    font-weight: 700;
  }
`;

export const ScopesCell: AdminTableCell = ({ t, className, ...adminAudit }) => {
  const { openModal } = useAllDataModal(adminAudit);

  return (
    <StyledCell onClick={() => openModal(AdminReportsModalEnum.SCOPES)}>
      <StyledScopes {...adminAudit} t={t} className={className} />
    </StyledCell>
  );
};
