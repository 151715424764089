import { MouseEvent as ReactMouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { CertificateDetails } from 'domains/supplier/types';

import { getCertificateDetailsPathname } from '../utils/getCertificateDetailsPathname';

type UseCertificateDetailsLinkType = (
  event: ReactMouseEvent<HTMLTableRowElement, MouseEvent>,
  row: CertificateDetails
) => void;

export const useCertificateDetailsLink = (): UseCertificateDetailsLinkType => {
  const { push } = useHistory();

  return (
    _: ReactMouseEvent<HTMLTableRowElement, MouseEvent>,
    row: CertificateDetails
  ) => {
    const { supplier, uuid } = row;
    const pathname = getCertificateDetailsPathname({
      supplierId: supplier.uuid,
      certificateId: uuid,
    });

    push(pathname);
  };
};
