/* eslint-disable react/destructuring-assignment */
import React from 'react';

import {
  AdminTableCell,
  isAdminAudit,
  isAdminCertificate,
} from '../../../types';
import { StyledPlainText } from '../../atoms/StyledPlainText';

export const AuditOptionCell: AdminTableCell = (props) => {
  let kind = '';

  if (isAdminAudit(props)) {
    kind = props.kind.name;
  }

  if (isAdminCertificate(props)) {
    kind = props?.auditKind?.name as string;
  }

  return <StyledPlainText>{kind}</StyledPlainText>;
};
