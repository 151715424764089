import { UNEXPECTED_ERROR } from 'config/constants';
import {
  AlertsState,
  AlertVariantEnum,
  isArrayOfString,
  Nullable,
} from 'types';

export const createWarningAlertState = (
  alerts?: string[]
): Nullable<AlertsState> =>
  alerts && alerts.length > 0
    ? {
        alerts,
        variant: AlertVariantEnum.WARNING,
      }
    : null;

export const createErrorAlertState = (alerts?: unknown): AlertsState => {
  let finalAlerts = [UNEXPECTED_ERROR];
  if (isArrayOfString(alerts)) {
    finalAlerts = alerts;
  }

  return {
    alerts: finalAlerts,
    variant: AlertVariantEnum.ERROR,
  };
};
