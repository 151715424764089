/* eslint-disable react/destructuring-assignment */
import React, { FC } from 'react';

import { MultiSelect } from 'components/atoms/MultiSelect';
import { isArrayOfString } from 'types';

import { getOptionsByRef } from '../../../config/localOptions';
import { MultiSelectFilter, UseFiltersAndSorting } from '../../../types';
import { convertRawOptionsToOptions } from '../../../utils/convertRawOptionsToOptions';
import { StyledTextField } from '../../atoms/StyledTextField';

type MultiSelectFilterCellProps = MultiSelectFilter & UseFiltersAndSorting;

export const MultiSelectFilterCell: FC<MultiSelectFilterCellProps> = (
  props
) => {
  const { removeValueFromFilterList, setFilterValue, filterKey, filters } =
    props;

  const options =
    'options' in props ? props.options : getOptionsByRef(props.optionsRef);
  const value = filters[filterKey];
  const valueToDisplay = isArrayOfString(value) ? value : [];
  const optionsToDisplay = convertRawOptionsToOptions(options);

  return (
    <MultiSelect
      renderInput={(params) => <StyledTextField {...params} />}
      onChange={(newValue) => {
        setFilterValue({ filterKey, value: newValue });
      }}
      onDelete={(valueToDelete) => {
        removeValueFromFilterList({ filterKey, value: valueToDelete });
      }}
      value={valueToDisplay}
      options={optionsToDisplay}
    />
  );
};
