import { alpha, styled } from '@mui/material';
import React from 'react';
import DatePicker from 'react-datepicker';

import { BLACK, NEVADA, PRIMARY, TEXT, WHITE } from 'config/appColors';

import { Button } from '../Button';

export const StyledDatePicker = styled((props) => (
  <DatePicker calendarClassName={props.className} {...props} />
))`
  && {
    border: none;
    padding: 5px 0;

    * {
      font-size: 1.4rem;
      font-family: 'Roboto', sans-serif;
      color: ${TEXT.PRIMARY};
    }

    .react-datepicker__month-container:first-of-type {
      border-right: 1px solid ${alpha(BLACK, 0.1)};
    }

    .react-datepicker__header {
      background: ${WHITE};
      border-bottom: none;
      margin-bottom: 15px;
    }

    .react-datepicker__current-month {
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 15px;
    }

    .react-datepicker__navigation-icon::before {
      border-color: ${TEXT.SECONDARY_DARK};
      height: 10px;
      width: 10px;
    }

    .react-datepicker__navigation--previous {
      top: 25px;
      left: 20px;
    }

    .react-datepicker__navigation--next {
      top: 25px;
      right: 20px;
    }

    .react-datepicker__day-name {
      padding: 0 17px;
      font-weight: 400;
      font-size: 1.2rem;
      color: ${TEXT.SECONDARY_DARK};
      display: inline-flex;
      justify-content: center;
    }

    .react-datepicker__month-container {
      padding: 20px;
    }

    .react-datepicker__week {
      border-radius: 36px;
      width: fit-content;
      overflow: hidden;
      margin-top: 5px;
      height: 36px;
    }

    .react-datepicker__day {
      width: 36px;
      height: 36px;
      line-height: 36px;
      font-weight: 400;
      font-size: 1.2rem;
      color: ${TEXT.PRIMARY};
      margin: 0;
      position: relative;
      z-index: 10;
      border-radius: 0;
    }

    .react-datepicker__day--keyboard-selected {
      background: transparent;
    }

    .react-datepicker__day:not(.react-datepicker__day--in-range):not(.react-datepicker__day--in-selecting-range):hover {
      border-radius: 50%;
    }

    .react-datepicker__day--outside-month {
      background: ${WHITE};
      height: 0;
      overflow: hidden;
    }

    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range {
      background: ${alpha(PRIMARY.MAIN, 0.1)};
    }

    .first-day-of-month,
    .react-datepicker__day--range-start,
    .react-datepicker__day--selecting-range-start {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    .last-day-of-month,
    .react-datepicker__day--range-end,
    .react-datepicker__day--selecting-range-end {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }

    .react-datepicker__day--selected:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__day--in-range) {
      border-radius: 50%;
      background: ${PRIMARY.MAIN};
      color: ${WHITE};
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end,
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--selecting-range-end {
      color: ${WHITE};
    }

    .react-datepicker__day--selecting-range-start::after,
    .react-datepicker__day--selecting-range-end::after,
    .react-datepicker__day--range-start::after,
    .react-datepicker__day--range-end::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      height: 36px;
      background: ${PRIMARY.MAIN};
      border-radius: 50%;
      z-index: -1;
    }
    .react-datepicker__header__dropdown {
      width: 100%;
      position: absolute;
      top: 10px;
      left: 0;
    }
    .react-datepicker__year-dropdown {
      width: 320px;
      display: flex;
      flex-wrap: wrap;
      background: ${WHITE};
      z-index: 11;

      &--scrollable {
        height: auto;
        max-height: 304px;
      }
    }

    .react-datepicker__year-option {
      width: 72px;
      height: 36px;
      margin: 8px 0;
      flex-basis: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 18px;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1.75;
      color: ${NEVADA};

      &:first-child,
      &:nth-last-child(2),
      &:last-child,
      &--selected {
        display: none;
      }

      &--selected_year {
        color: ${WHITE};
        background: ${PRIMARY[500]};
      }
    }

    .react-datepicker__year-read-view {
      min-width: 130px;
      min-height: 20px;

      &--down-arrow,
      &--selected-year {
        display: none;
      }
    }
  }
` as unknown as typeof DatePicker;

export const StyledContainer = styled('div')`
  display: flex;
  padding: 10px;
`;

export const StyledInputContainer = styled('div')`
  display: inline-flex;
  align-items: center;
  gap: 5px;
`;

export const StyledButton = styled((props) => (
  <Button {...props} size="small" color="info" variant="contained" />
))`
  text-transform: capitalize;
  border-radius: 36px;
  width: fit-content;
`;

export const StyledButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  height: 450px;
`;

export const StyledHeading = styled('p')`
  padding-left: 30px;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 10px 0 0;
  letter-spacing: 0.015rem;
  color: ${TEXT.PRIMARY};
  text-transform: uppercase;
`;
