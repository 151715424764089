import { styled, TableCell } from '@mui/material';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

import { CELLS_MAP } from '../../../config/adminTableColumns';
import { AdminColumn, AdminRecord } from '../../../types';

const StyledTableCell = styled(TableCell)<{ $width?: string }>`
  width: ${({ $width }) => $width};
`;

interface AllDataTableCellProps {
  adminRecord: AdminRecord;
  columnConfig: AdminColumn;
  t: TFunction;
}

export const AllDataTableCell: FC<AllDataTableCellProps> = ({
  adminRecord,
  columnConfig,
  t,
}) => {
  const CellComponent = CELLS_MAP[columnConfig.id];
  return (
    <StyledTableCell $width={columnConfig.width}>
      <CellComponent
        {...adminRecord}
        t={t}
        filtersConfig={columnConfig?.filtersConfig}
      />
    </StyledTableCell>
  );
};
