import { styled } from '@mui/material';
import React from 'react';

import { StyledLink } from 'components/atoms/StyledLink';
import { TEXT } from 'config/appColors';

const StyledLinkButton = styled((props) => (
  <StyledLink as="button" {...props} />
))`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 6px;
    font-size: 2.1rem;
    color: ${TEXT.SECONDARY_DARK};
  }
`;

StyledLinkButton.displayName = 'StyledLinkButton';

export { StyledLinkButton };
