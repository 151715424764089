import React from 'react';

import { AdminTableCell, isAdminAudit } from '../../../types';
import { StyledPlainText } from '../../atoms/StyledPlainText';

export const TypeCell: AdminTableCell = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const type = isAdminAudit(props) ? props.type : props.certificateType;

  return <StyledPlainText>{type?.name}</StyledPlainText>;
};
