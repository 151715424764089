import { useDispatch } from 'react-redux';

import { openAdminReportsModal, setAdminRecord } from '../state/modal/slice';
import { AdminReportsModalEnum } from '../state/modal/types';
import { AdminRecord } from '../types';

interface UseAllDataModalType {
  openModal: (modalName: AdminReportsModalEnum) => void;
}

export const useAllDataModal = (record: AdminRecord): UseAllDataModalType => {
  const dispatch = useDispatch();
  const openModal = (modalName: AdminReportsModalEnum) => {
    dispatch(openAdminReportsModal(modalName));
    dispatch(setAdminRecord(record));
  };

  return {
    openModal,
  };
};
