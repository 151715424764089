import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Heading from 'components/atoms/Heading';
import { ERROR, TEXT } from 'config/appColors';
import { UNEXPECTED_ERROR_TRY_AGAIN } from 'config/constants';
import { isArrayOfString } from 'types';

interface Dense {
  $dense?: boolean;
}

const StyledCenteredContent = styled('div')<Dense>`
  display: flex;
  flex-direction: ${({ $dense }) => ($dense ? 'row-reverse' : 'column')};
  justify-content: center;
  align-items: center;
  padding: ${({ $dense }) => ($dense ? '20px 170px' : '30px 170px')};
  width: 100%;
  color: ${TEXT.PRIMARY};
`;

const StyledErrorIcon = styled(ErrorIcon)<Dense>`
  font-size: ${({ $dense }) => ($dense ? '2.4rem' : '5rem')};
  margin: ${({ $dense }) => ($dense ? '0' : '20px 0')};
  color: ${ERROR.LIGHT};
`;

const StyledResponseStatusHeading = styled(Heading)<Dense>`
  line-height: 2rem;
  letter-spacing: 0.04rem;
  text-align: center;
  font-weight: ${({ $dense }) => ($dense ? 400 : 500)};
  color: ${TEXT.PRIMARY};
  margin-left: ${({ $dense }) => ($dense ? '10px ' : '0')};
  margin-bottom: ${({ $dense }) => ($dense ? '0' : '14px')};

  && {
    font-size: ${({ $dense }) => ($dense ? '1.2rem' : '1.8rem')};
  }
`;

const StyledErrorDescription = styled('p')`
  font-weight: 400;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.025rem;
  max-width: 420px;
  margin-bottom: 0;
`;

const StyledErrorCode = styled('p')<Dense>`
  max-width: 420px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: center;
`;

interface ErrorStateProps {
  heading?: string;
  description?: string | string[];
  errorCode?: string;
  customErrorLabel?: string;
  customErrorIcon?: ReactNode;
  className?: string;
  dense?: boolean;
}

export const ErrorState: FC<ErrorStateProps> = (props) => {
  const {
    heading,
    description,
    errorCode,
    customErrorLabel,
    customErrorIcon,
    className,
    dense,
  } = props;
  const [t] = useTranslation('common');

  const label = customErrorLabel || t('Error Code');
  const codeWithLabel = `${label} ${errorCode}`;

  let descriptionToDisplay = t(UNEXPECTED_ERROR_TRY_AGAIN);

  if (typeof description === 'string') {
    descriptionToDisplay = description;
  }

  if (isArrayOfString(description)) {
    descriptionToDisplay = description.join('. ');
  }

  return (
    <StyledCenteredContent className={className} $dense={dense}>
      <StyledResponseStatusHeading variant="h3" $dense={dense}>
        {heading} <br />
        {dense && !!errorCode && codeWithLabel}
      </StyledResponseStatusHeading>
      {customErrorIcon || <StyledErrorIcon $dense={dense} />}
      {!dense && (
        <StyledErrorDescription>{descriptionToDisplay}</StyledErrorDescription>
      )}
      {!dense && !!errorCode && (
        <StyledErrorCode $dense={dense}>
          {customErrorLabel || codeWithLabel}
        </StyledErrorCode>
      )}
    </StyledCenteredContent>
  );
};
