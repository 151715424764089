import React from 'react';

import { AdminTableCell, isAdminAudit } from '../../../types';
import { StyledPlainText } from '../../atoms/StyledPlainText';

export const ExecutionModeCell: AdminTableCell = (props) => {
  if (!isAdminAudit(props)) {
    return null;
  }
  const { executionMode } = props;
  return <StyledPlainText>{executionMode.name}</StyledPlainText>;
};
