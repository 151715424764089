import { RawOption } from 'types';

import { countries } from './countries';

const LOCAL_OPTIONS: Record<string, RawOption[]> = {
  allCountryList: countries,
};

export const getOptionsByRef = (ref: string): RawOption[] => {
  const options = LOCAL_OPTIONS[ref];

  if (!options) {
    console.warn(`System could not find options for the following ref: ${ref}`);
    return [];
  }

  return options;
};
