import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AdminRecord } from '../../../types';
import { Scopes } from '../../molecules/DataCells';
import { TemplateModal } from '../../templates/TemplateModal';

const StyledScopes = styled(Scopes)`
  .title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 3.2rem;
    margin: 15px 0;
  }

  li {
    margin: 3px 0 3px 10px;
    line-height: 2rem;
    letter-spacing: 0.017rem;
    font-size: 1.4rem;
  }
`;

export const ScopesModal: FC<AdminRecord> = (props) => {
  const [t] = useTranslation('adminReports');

  return (
    <TemplateModal title={t('Scopes')}>
      <StyledScopes {...props} t={t} showAllScopesLevels />
    </TemplateModal>
  );
};
