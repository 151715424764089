import {
  TableHead as MuiTableHead,
  styled,
  TableCell,
  TableRow,
} from '@mui/material';
import React, { FC } from 'react';
import { createGlobalStyle } from 'styled-components';

import { getNewDirection } from 'components/organisms/Table';
import { StyledArrowUpwardIcon } from 'components/organisms/Table/Table.styled';
import { generateId } from 'utils/generateId';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { AllDataTableApiVariant } from '../../../config/allDataApiConfig';
import { useFiltersAndSorting } from '../../../hooks/useFiltersAndSorting';
import { AdminColumn, FilterTypeEnum, TableFilter } from '../../../types';
import { FilterCell } from '../../molecules/FilterCells';

/**
 * GlobalStyles for aligning options in Select from MUI
 */
const GlobalStyle = createGlobalStyle`
  .MuiPaper-elevation.MuiMenu-paper.MuiPopover-paper{
    transform: translateY(-10px)!important;

    &, * {
      transition: none;
      animation: none;
    }

    box-shadow: rgba(0, 0, 0, 0.15) 0 2px 10px;
  }
`;

const StyledTableCell = styled(TableCell)`
  & > * + * {
    margin-top: 5px;
  }
`;

interface AllDataTableHeadProps {
  columns: AdminColumn[];
  variant: AllDataTableApiVariant;
}

export const AllDataTableHead: FC<AllDataTableHeadProps> = ({
  columns,
  variant,
}) => {
  const filtersAndSorting = useFiltersAndSorting({ variant });
  const { sortState, setSortState, filtersReadyToFetch } = filtersAndSorting;

  const handleSortClick = (key: string) => {
    setSortState({ direction: getNewDirection(key, sortState), key });
  };

  const getKey = (filter: TableFilter) => {
    if (filter.type === FilterTypeEnum.DATE_RANGE) {
      return `${filter.startFilterKey}${filter.endFilterKey}`;
    }
    return filter.filterKey;
  };

  return (
    <>
      <GlobalStyle />
      <MuiTableHead className="table-head">
        <TableRow className="table-head__first-row">
          {columns.map(({ headerText, width, sortKey }, index) => (
            <TableCell
              key={generateId(headerText.en, index)}
              style={{ width }}
              onClick={() => sortKey && handleSortClick(sortKey)}
              className={sortKey ? 'sortable' : ''}
            >
              {getTranslatedValue(headerText)}
              {sortKey && sortState?.key === sortKey && (
                <StyledArrowUpwardIcon $direction={sortState?.direction} />
              )}
            </TableCell>
          ))}
        </TableRow>
        <TableRow className="table-head__second-row bg-gray">
          {columns.map((column, index) => {
            const { headerText, width } = column;
            return (
              <StyledTableCell
                key={generateId(headerText.en, index)}
                style={{ width }}
              >
                {'filtersConfig' in column &&
                  filtersReadyToFetch &&
                  column.filtersConfig?.map((config, idx) => (
                    <FilterCell
                      {...config}
                      {...filtersAndSorting}
                      key={generateId(getKey(config), idx)}
                    />
                  ))}
              </StyledTableCell>
            );
          })}
        </TableRow>
      </MuiTableHead>
    </>
  );
};
