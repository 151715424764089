import React from 'react';

import { formatDate } from 'utils/dates';

import { AdminTableCell, isAdminCertificate } from '../../../types';
import { StyledPlainText } from '../../atoms/StyledPlainText';

export const CertificateUpdatedAtCell: AdminTableCell = (props) => {
  if (!isAdminCertificate(props)) {
    return null;
  }

  const { lastModifiedAt } = props;

  return <StyledPlainText>{formatDate(lastModifiedAt)}</StyledPlainText>;
};
