import { Popover } from '@mui/material';
import { isEqual } from 'date-fns';
import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from 'types';

import { DateRange } from './DateRange';
import { DateRangePicker } from './DateRangePicker';
import {
  StyledContainer,
  StyledDatePicker,
  StyledHeading,
  StyledInputContainer,
} from './DateRangePicker.styled';
import { PreselectedDateRangePicker } from './PreselectedDateRangePicker';
import { getClassName, getDate } from './utils';

const getNullableDate = (date: Nullable<string>) =>
  date ? getDate(date) : null;

const datesAreEqual = (date1: Nullable<Date>, date2: Nullable<Date>) => {
  if (date1 === null && date2 === null) {
    return true;
  }
  if (date1 === null || date2 === null) {
    return false;
  }

  return isEqual(date1, date2);
};

interface DateRangePickerWithCalendarProps {
  startDate: Nullable<string>;
  endDate: Nullable<string>;
  onStartDateChange: (date: Nullable<Date>) => void;
  onEndDateChange: (date: Nullable<Date>) => void;
  className?: string;
}

export const DateRangePickerWithCalendar: FC<DateRangePickerWithCalendarProps> =
  (props) => {
    const [t] = useTranslation('components');
    const ref = useRef<Nullable<HTMLDivElement>>(null);
    const [anchorEl, setAnchorEl] = useState<Nullable<HTMLDivElement>>(null);
    const {
      endDate: endDateString,
      startDate: startDateString,
      onEndDateChange,
      onStartDateChange,
      className,
    } = props;
    const startDateObject = getNullableDate(startDateString);
    const endDateObject = getNullableDate(endDateString);

    const handleDateRangePickerChange = ([
      startDate,
      endDate,
    ]: Nullable<Date>[]) => {
      if (!datesAreEqual(startDateObject, startDate)) {
        onStartDateChange(startDate);
      }

      if (!datesAreEqual(endDateObject, endDate)) {
        onEndDateChange(endDate);
      }
    };

    const handleDateInputsClick = () => {
      setAnchorEl(ref.current);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const handleManualDateChange =
      (onDateChange: (date: Nullable<Date>) => void) => (date: string) =>
        onDateChange(getNullableDate(date));

    return (
      <div className={className}>
        <StyledInputContainer ref={ref}>
          <DateRangePicker
            startDate={startDateString || ''}
            endDate={endDateString || ''}
            onClick={handleDateInputsClick}
            onEndChange={handleManualDateChange(onEndDateChange)}
            onStartChange={handleManualDateChange(onStartDateChange)}
          />
        </StyledInputContainer>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <StyledContainer>
            <PreselectedDateRangePicker
              onChange={handleDateRangePickerChange}
            />
            <div>
              <StyledHeading>{t('select date range')}</StyledHeading>
              <DateRange startDate={startDateObject} endDate={endDateObject} />
              <StyledDatePicker
                selected={startDateObject}
                onChange={handleDateRangePickerChange}
                startDate={startDateObject}
                endDate={endDateObject}
                monthsShown={2}
                dayClassName={getClassName}
                selectsRange
                inline
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={26}
              />
            </div>
          </StyledContainer>
        </Popover>
      </div>
    );
  };
