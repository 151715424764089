import { styled } from '@mui/material';
import { get } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, WithUndefined } from 'types';
import { formatDate, getDateFromString } from 'utils/dates';

import { StyledSubItemsContainer } from './StyledElements';
import {
  DateInputType,
  DynamicFormCommonProps,
  DynamicFormItemCommonProps,
} from './types';
import { DatePicker } from '../../atoms/DatePicker';
import { DynamicFormItemLabel } from '../../atoms/DynamicFormItemLabel';

type DateInputProps = DateInputType &
  DynamicFormCommonProps &
  DynamicFormItemCommonProps;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export const DateInput: FC<DateInputProps> = ({
  values,
  setValue,
  props,
  validationState,
  validateFormItem,
  subItems,
}) => {
  const [t] = useTranslation('components');
  const value = get(values, props.dataRef);
  const { dataRef, label, earliest, latest } = props;
  const [isValidationNeeded, setIsValidationNeeded] = useState(false);

  useEffect(() => {
    if (isValidationNeeded) {
      validateFormItem(value, dataRef);
      setIsValidationNeeded(false);
    }
  }, [isValidationNeeded, value, dataRef, validateFormItem]);

  const changeDate = (date: Nullable<Date>) => {
    const formattedDate = formatDate(date, 'yyyy-MM-dd');

    setValue(dataRef, formattedDate);
    setIsValidationNeeded(true);
  };
  const error = get(validationState, dataRef) as WithUndefined<string>;

  return (
    <div>
      <DynamicFormItemLabel text={label} error={error} />
      <StyledDatePicker
        inputPlaceholder={t('select date')}
        value={value as string}
        onChange={changeDate}
        minDate={getDateFromString(earliest)}
        maxDate={getDateFromString(latest)}
        onClose={() => {
          setIsValidationNeeded(true);
        }}
      />
      {!!subItems && (
        <StyledSubItemsContainer>{subItems}</StyledSubItemsContainer>
      )}
    </div>
  );
};
