import {
  AdditionalInformationCell,
  AuditOptionCell,
  AuditorsCell,
  AuditPlannedCell,
  AuditTimesCell,
  CertificateCell,
  CertificateCreatedAtCell,
  CertificateExtensionCell,
  CertificateIssuedAtCell,
  CertificateLocksCell,
  CertificateRecertificationCell,
  CertificateUpdatedAtCell,
  CertificateValidityCell,
  CertificationBodyCell,
  DocumentsCell,
  ExceptionalCircumstancesCell,
  ExecutionModeCell,
  IdCell,
  ResultCell,
  ScopesCell,
  ScopesDescriptionCell,
  SourceTypeCell,
  StandardCell,
  StatusCell,
  SupplierCell,
  SupplierCityCell,
  SupplierCountryCell,
  SupplierHeadquarterCell,
  TagsCell,
  TypeCell,
  UploadedCell,
} from '../components/molecules/DataCells';
import { AdminTableCell } from '../types';

export const CELLS_MAP: Record<string, AdminTableCell> = {
  auditId: IdCell,
  supplierLink: SupplierCell,
  supplierCity: SupplierCityCell,
  supplierCountry: SupplierCountryCell,
  supplierHeadquarter: SupplierHeadquarterCell,
  auditModuleId: StandardCell,
  moduleId: StandardCell,
  auditType: TypeCell,
  auditOption: AuditOptionCell,
  auditPlan: AuditPlannedCell,
  auditTimes: AuditTimesCell,
  auditUploaded: UploadedCell,
  auditResult: ResultCell,
  auditScopes: ScopesCell,
  auditScopeDescription: ScopesDescriptionCell,
  auditTags: TagsCell,
  auditDocuments: DocumentsCell,
  certificationBody: CertificationBodyCell,
  auditors: AuditorsCell,
  certificate: CertificateCell,
  certificateRecertification: CertificateRecertificationCell,
  certificateLocks: CertificateLocksCell,
  certificateExceptionalCircumstances: ExceptionalCircumstancesCell,
  additionalInformation: AdditionalInformationCell,
  auditExecutionMode: ExecutionModeCell,
  certId: IdCell,
  certificateStatus: StatusCell,
  certificateSource: SourceTypeCell,
  certificateType: TypeCell,
  certificateExtension: CertificateExtensionCell,
  certificateValidity: CertificateValidityCell,
  certificateIssuedAt: CertificateIssuedAtCell,
  certificateCreatedAt: CertificateCreatedAtCell,
  certificateUpdatedAt: CertificateUpdatedAtCell,
  certificateResult: ResultCell,
  certificateScopes: ScopesCell,
  certificateScopeDescription: ScopesDescriptionCell,
  certificateTags: TagsCell,
  certificateDocuments: DocumentsCell,
};

export const SUPPORTED_CELLS_KEYS = Object.keys(CELLS_MAP);
