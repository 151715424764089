import { FileCopy, LockClock, Toc } from '@mui/icons-material';
import { styled } from '@mui/material';
import React from 'react';

import { useAllDataModal } from '../../../hooks/useAllDataModal';
import { AdminReportsModalEnum } from '../../../state/modal/types';
import {
  AdminTableCell,
  isAdminAudit,
  isAdminCertificate,
} from '../../../types';
import { StyledLinkButton } from '../../atoms/StyledLinkButton';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

export const AdditionalInformationCell: AdminTableCell = ({ t, ...record }) => {
  const { openModal } = useAllDataModal(record);
  const isAudit = isAdminAudit(record);
  const isCertificate = isAdminCertificate(record);
  const showLockHistoryLabel =
    (isAudit && record?.certificate?.uuid) || isCertificate;
  return (
    <StyledContainer>
      {showLockHistoryLabel && (
        <StyledLinkButton
          onClick={() => openModal(AdminReportsModalEnum.LOCK_HISTORY)}
        >
          <LockClock fontSize="large" /> {t('Lock History')}
        </StyledLinkButton>
      )}

      <StyledLinkButton
        onClick={() => openModal(AdminReportsModalEnum.AUDIT_LOGS)}
      >
        <Toc fontSize="large" />{' '}
        {isCertificate
          ? t('Certificate Logs', { ns: 'certificateDetails' })
          : t('Audit Logs')}
      </StyledLinkButton>

      {isCertificate && (
        <StyledLinkButton
          onClick={() => openModal(AdminReportsModalEnum.CERTIFICATE_AUDITS)}
        >
          <FileCopy fontSize="large" /> {t('Audits')}
        </StyledLinkButton>
      )}
    </StyledContainer>
  );
};
