import { FC } from 'react';
import { TFunction } from 'react-i18next';

import { SortStateType } from 'components/organisms/Table/types';
import { Nullable, RawOption, TranslatedText } from 'types';

import { AdminRecord } from './domain';
import { AllDataTableApiVariant } from '../config/allDataApiConfig';

export enum FilterTypeEnum {
  TEXT = 'TEXT',
  CHECKBOX = 'CHECKBOX',
  MULTISELECT = 'MULTISELECT',
  DATE = 'DATE',
  DATE_RANGE = 'DATE_RANGE',
  SINGLESELECT = 'SINGLESELECT',
}
export interface CheckboxFilter {
  type: FilterTypeEnum.CHECKBOX;
  defaultValue: Nullable<boolean>;
  label?: string;
  filterKey: string;
}

export interface TextFieldFilter {
  type: FilterTypeEnum.TEXT;
  defaultValue?: string;
  filterKey: string;
  minLength?: number;
  exact?: boolean;
}

export type MultiSelectFilter =
  | {
      type: FilterTypeEnum.MULTISELECT;
      defaultValue?: string[];
      filterKey: string;
    } & (
      | {
          options: RawOption[];
        }
      | {
          optionsRef: string;
        }
    );

export interface DateFilter {
  type: FilterTypeEnum.DATE;
  defaultValue?: string;
  filterKey: string;
}

export interface DateRangeFilter {
  type: FilterTypeEnum.DATE_RANGE;
  startDefaultValue?: string;
  endDefaultValue?: string;
  startFilterKey: string;
  endFilterKey: string;
}

export interface SingleSelectFilter {
  type: FilterTypeEnum.SINGLESELECT;
  defaultValue?: string;
  filterKey: string;
  options: RawOption[];
}

export type TableFilter =
  | CheckboxFilter
  | TextFieldFilter
  | MultiSelectFilter
  | DateFilter
  | DateRangeFilter
  | SingleSelectFilter;

export interface AdminColumn {
  id: string;
  headerText: TranslatedText;
  width?: string;
  hidable?: boolean;
  sortKey?: string;
  filtersConfig?: TableFilter[];
  resizable?: boolean;
}

export type AdminTableCell<
  T extends Record<string, unknown> = Record<string, unknown>
> = FC<
  AdminRecord & {
    t: TFunction<'adminReports', undefined>;
    className?: string;
    filtersConfig?: TableFilter[];
  } & T
>;

export type PrimitiveFilter = Nullable<boolean> | string;

export type FilterType = PrimitiveFilter | string[];

export type Filters = Record<string, FilterType>;

export type Payload<T = PrimitiveFilter> = {
  filterKey: string;
  value: T;
  variant: AllDataTableApiVariant;
};

type PayloadWithoutVariant<T = PrimitiveFilter> = Omit<Payload<T>, 'variant'>;

export interface UseFiltersAndSorting {
  filters: Filters;
  URLFilters: Filters;
  removeValueFromFilterList: (payload: PayloadWithoutVariant<string>) => void;
  setFilterValue: (
    payload: PayloadWithoutVariant | PayloadWithoutVariant<string[]>
  ) => void;
  sortState: Nullable<SortStateType>;
  URLSortState: Nullable<SortStateType>;
  setSortState: (newSortState: SortStateType) => void;
  resetFiltersAndSorting: () => void;
  filtersReadyToFetch: boolean;
  updateURLFiltersAndSorting: () => void;
}
