import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from 'types';

import { StyledButton, StyledButtonContainer } from './DateRangePicker.styled';

const today = new Date();
const dateRanges = {
  lastMonth: [
    startOfMonth(subMonths(today, 1)),
    endOfMonth(subMonths(today, 1)),
  ],
  currentMonth: [startOfMonth(today), endOfMonth(today)],
  lastYear: [startOfYear(subYears(today, 1)), endOfYear(subYears(today, 1))],
  currentYear: [startOfYear(today), endOfYear(today)],
};

const { lastMonth, currentMonth, lastYear, currentYear } = dateRanges;

const config = [
  { payload: lastMonth, label: 'Last Month' },
  { payload: currentMonth, label: 'Current Month' },
  { payload: lastYear, label: 'Last Year' },
  { payload: currentYear, label: 'Current Year' },
  { payload: [null, null], label: 'Reset' },
];

interface PreselectedDateRangePickerProps {
  onChange: (dates: Nullable<Date>[]) => void;
}

export const PreselectedDateRangePicker: FC<PreselectedDateRangePickerProps> =
  ({ onChange }) => {
    const [t] = useTranslation('components');
    return (
      <StyledButtonContainer>
        {config.map(({ label, payload }) => (
          <StyledButton key={label} onClick={() => onChange(payload)}>
            {t(label)}
          </StyledButton>
        ))}
      </StyledButtonContainer>
    );
  };
