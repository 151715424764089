import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { Document } from 'types';
import { externalServiceInstance } from 'utils/apiClient/axiosInstance';
import { downloadFile } from 'utils/downloadFile';
import { getBaseQueryError } from 'utils/getBaseQueryError';
import { validateAndLogErrors } from 'utils/schemaValidator';

import { DocumentsParams, DownloadDocumentParams } from './types';
import endpoints from '../../config/endpoints';
import { documentSchema } from '../../schemas/Document';

const documentsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditDetailsDocuments: build.query<Document[], DocumentsParams>({
      query: ({ auditUuid }) => ({
        url: endpoints.DOCUMENTS_TAB.LIST(auditUuid),
      }),
      transformResponse: validateAndLogErrors(documentSchema),
      providesTags: ['AUDIT_DETAILS_DOCUMENTS'],
      keepUnusedDataFor: 10,
    }),
    downloadAuditDetailsDocument: build.mutation<
      unknown,
      DownloadDocumentParams
    >({
      async queryFn(
        {
          auditUuid,
          documentUuid,
          fileName,
          customUrl = endpoints.DOCUMENTS_TAB.DOWNLOAD,
        },
        _,
        extraOptions,
        baseQuery
      ) {
        const res = (await baseQuery({
          url: customUrl(auditUuid, documentUuid),
        })) as QueryReturnValue<{ url: string }>;

        if (res?.data?.url) {
          try {
            const gcpResponse = await externalServiceInstance({
              url: res.data.url,
              responseType: 'arraybuffer',
            });

            await downloadFile(gcpResponse.data as ArrayBuffer, fileName);
          } catch {
            return getBaseQueryError();
          }
        }

        if (res.error) {
          return getBaseQueryError();
        }

        return { data: null };
      },
    }),
  }),
});

export const useGetAuditDetailsDocumentsQuery = (params: DocumentsParams) => {
  const query = documentsApi.useGetAuditDetailsDocumentsQuery(params);
  const status = useRTKQueryStatus(query, true);
  const error = useFormattedError(query);

  return {
    ...query,
    documents: query.data || [],
    error,
    status,
  };
};

export const useDownloadAuditDetailsDocumentMutation = () => {
  const [downloadFileViaAPI] =
    documentsApi.useDownloadAuditDetailsDocumentMutation();

  const requestMethod = (params: DownloadDocumentParams) =>
    downloadFileViaAPI(params).unwrap();

  return {
    downloadFile: requestMethod,
  };
};
