import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SortStateType } from 'components/organisms/Table/types';
import { RootState } from 'store';
import { Nullable } from 'types';

import {
  AllDataFiltersAndSortState,
  AllDataFiltersAndSortStatePerCollection,
} from './types';
import { AllDataTableApiVariant } from '../../config/allDataApiConfig';
import { parentReducerName } from '../../config/constants';
import { FilterType, Payload } from '../../types';

const initialState: AllDataFiltersAndSortState = {
  audits: {
    filtersReadyToFetch: false,
    filters: {},
    sortState: null,
    searchCounter: 0,
  },
  certificates: {
    filtersReadyToFetch: false,
    filters: {},
    sortState: null,
    searchCounter: 0,
  },
};

const allDataListSlice = createSlice({
  initialState,
  name: 'allDataList',
  reducers: {
    setFiltersReadyToFetch: (
      state,
      action: PayloadAction<AllDataTableApiVariant>
    ) => {
      state[action.payload].filtersReadyToFetch = true;
    },
    resetFilters: (state, action: PayloadAction<AllDataTableApiVariant>) => {
      state[action.payload].filters = initialState[action.payload].filters;
    },
    setFilterValue: (
      state,
      action: PayloadAction<Payload | Payload<string[]>>
    ) => {
      const { filterKey, value, variant } = action.payload;
      state[variant].filters[filterKey] = value;
    },
    removeValueFromFilterList: (
      state,
      action: PayloadAction<Payload<string>>
    ) => {
      const { variant, filterKey, value } = action.payload;
      const filterList = state[variant].filters[filterKey];

      if (Array.isArray(filterList)) {
        state[variant].filters[filterKey] = filterList.filter(
          (filter) => filter !== value
        );
      } else if (filterKey in state) {
        // console.warn only for development purposes, we can get rid of it once table is finished
        console.warn(
          "You're trying to remove element of filter list by passing wrong key"
        );
      }
    },
    resetSortState: (state, action: PayloadAction<AllDataTableApiVariant>) => {
      state[action.payload].sortState = null;
    },
    setSortState: (
      state,
      action: PayloadAction<SortStateType & { variant: AllDataTableApiVariant }>
    ) => {
      state[action.payload.variant].sortState = action.payload;
    },
    resetAllDataListState: () => initialState,
    updateFiltersAndSortState: (
      state,
      action: PayloadAction<
        Pick<
          AllDataFiltersAndSortStatePerCollection,
          'filters' | 'sortState'
        > & { variant: AllDataTableApiVariant }
      >
    ) => {
      state[action.payload.variant].filters = action.payload.filters;
      state[action.payload.variant].sortState = action.payload.sortState;
    },
    increaseSearchCounter: (
      state,
      action: PayloadAction<AllDataTableApiVariant>
    ) => {
      state[action.payload].searchCounter += 1;
    },
  },
});

export const {
  setFiltersReadyToFetch,
  removeValueFromFilterList,
  resetFilters,
  setFilterValue,
  resetAllDataListState,
  resetSortState,
  setSortState,
  updateFiltersAndSortState,
  increaseSearchCounter,
} = allDataListSlice.actions;

export const selectFilters =
  (variant: AllDataTableApiVariant) =>
  (state: RootState): Record<string, FilterType> =>
    state[parentReducerName].allDataList[variant].filters;

export const selectSortState =
  (variant: AllDataTableApiVariant) =>
  (state: RootState): Nullable<SortStateType> =>
    state[parentReducerName].allDataList[variant].sortState;

export const selectAreFiltersReadyToFetch =
  (variant: AllDataTableApiVariant) =>
  (state: RootState): boolean =>
    state[parentReducerName].allDataList[variant].filtersReadyToFetch;

export const selectSearchCounter =
  (variant: AllDataTableApiVariant) =>
  (state: RootState): number =>
    state[parentReducerName].allDataList[variant].searchCounter;

export default allDataListSlice.reducer;
