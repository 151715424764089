import { styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StatusIndicator } from 'components/atoms/StatusIndicator';
import { StyledResult } from 'components/atoms/StyledResult';
import {
  Column,
  Table,
  useColumnConfig,
  usePagination,
} from 'components/organisms/Table';
import { SortStateType } from 'components/organisms/Table/types';
import { ERROR } from 'config/appColors';
import { deletedAuditStatuses } from 'domains/supplier/config/constants';
import { useAuditListDetailsLink } from 'hooks/useAuditListDetailsLink';
import { Audit, AuditStatusEnum, Nullable } from 'types';
import { renderDateRange } from 'utils/dates';

import { useGetAuditListQuery } from '../../../state/auditList/api';
import { selectSupplierDetails } from '../../../state/details/slice';
import { TableCellWithLockIcon } from '../../atoms/TableCellWithLockIcon';

const StyledTable = styled(Table)`
  table,
  .skeleton-container {
    min-width: 1060px;
  }

  .skeleton-container {
    padding: 0 10px;
  }
` as typeof Table;

interface AuditListTableProps {
  showDeletedAudits?: boolean;
}

export const AuditListTable: FC<AuditListTableProps> = (props) => {
  const { showDeletedAudits } = props;
  const [t] = useTranslation('supplier');

  const supplierId = useSelector(selectSupplierDetails)?.uuid || '';
  const { pagination, limit, offset, setTotalRowsAmount } = usePagination();
  const [sortState, setSortState] = useState<Nullable<SortStateType>>(null);

  const onSortClick = (newSortState: Nullable<SortStateType>) => {
    setSortState(newSortState);
  };

  const currentStatus = showDeletedAudits
    ? deletedAuditStatuses
    : [AuditStatusEnum.PUBLISHED];

  const {
    auditList,
    status: requestStatus,
    error,
    total,
  } = useGetAuditListQuery({
    limit,
    offset,
    status: currentStatus,
    supplierId,
    ...(sortState && { sort: `${sortState.key}:${sortState.direction}` }),
  });

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  const { getColumnConfig, getColumnConfigWithEllipsis } =
    useColumnConfig<Audit>('10%');

  const handleRowClick = useAuditListDetailsLink();

  const publishedAuditColumns: Column<Audit>[] = [
    {
      customCell: ({ type }) => type?.name,
      headerName: t('Type'),
      sortKey: 'type',
      onSortClick,
      ...getColumnConfig(),
    },
    {
      customCell: ({ kind }) => kind?.name,
      headerName: t('Option'),
      sortKey: 'kind',
      onSortClick,
      ...getColumnConfigWithEllipsis('10%'),
    },
  ];
  const deletedAuditColumns: Column<Audit>[] = [
    {
      customCell: ({ status }) => (
        <StatusIndicator label={status?.name} bgColor={ERROR.LIGHT} />
      ),
      headerName: t('Status'),
      sortKey: 'status',
      onSortClick,
      ...getColumnConfig('15%'),
    },
  ];

  const columns: Column<Audit>[] = [
    {
      customCell: ({ auid, certificate }) => (
        <TableCellWithLockIcon
          certificate={certificate}
          customLabel={auid}
          useTrimmedText={false}
        />
      ),
      headerName: t('Audit/assessment ID (AUID)'),
      sortKey: 'ceid',
      onSortClick,
      ...getColumnConfig('15%'),
    },
    {
      customCell: ({ module }) => module?.name,
      headerName: t('Standards/Programs'),
      sortKey: 'module',
      onSortClick,
      ...getColumnConfig('15%'),
    },
    {
      customCell: ({ auditTimeframe }) =>
        auditTimeframe
          ? renderDateRange(auditTimeframe.startDate, auditTimeframe.endDate)
          : '-',
      headerName: t('Dates'),
      sortKey: 'auditTimeframe.startDate',
      onSortClick,
      ...getColumnConfig('20%'),
    },
    {
      customCell: ({ result }) => (
        <StyledResult $color={result?.overall?.color}>
          {result?.overall?.text || ''}
        </StyledResult>
      ),
      headerName: t('Result'),
      sortKey: 'result',
      onSortClick,
      ...getColumnConfig('15%'),
    },
    ...(showDeletedAudits ? deletedAuditColumns : publishedAuditColumns),
    {
      customCell: ({ certificationBody }) => certificationBody?.name,
      headerName: t('Certification body/assessment service provider'),
      sortKey: 'certificationBody',
      onSortClick,
      ...getColumnConfigWithEllipsis('15%'),
    },
  ];

  return (
    <StyledTable
      rows={auditList}
      columns={columns}
      onRowClick={handleRowClick}
      status={requestStatus}
      error={error}
      pagination={pagination}
      sortState={sortState}
      dense
    />
  );
};
